import React from 'react';
import { Alert } from 'react-bootstrap';
import styles from './customAlertBox.module.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip'

export const CustomAlertBox = (props) => {
    return (
        <div>
            {!props.isCancelable ?
                <Alert key={props.keyCode}  className={`

                    ${props.alertType === 'dangerAlert' && styles.dangerAlert}
                    ${props.alertType === 'warningAlert' && styles.warningAlert}
                    ${props.alertType === 'successAlert' && styles.successAlert}    
                    ${props.alertType === 'defaultAlert' && styles.defaultAlert} 
                `}>
                    {props.svgStatus === "false" ?
                        null 
                        :
                        <svg className={styles.infoIcon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 8V12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 16H12.01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    }
                    {props.alertBodyText}
                    {props.isExternalLinkAvailable ? <Alert.Link className={styles.linkDeafult} href={props.externalLink} target='_blank'>{props.externalLinkLable} <i class="fas fa-external-link-alt"></i></Alert.Link>:''}
                </Alert>
                : 
                <Alert key={props.keyCode} className={`
                ${props.alertType === 'dangerAlert' && styles.dangerAlert}
                ${props.alertType === 'warningAlert' && styles.warningAlert}
                ${props.alertType === 'successAlert' && styles.successAlert}  
                ${props.alertType === 'defaultAlert' && styles.defaultAlert} 
                `} onClose={() => props.cancelButtonClick()} dismissible>
                    {props.svgStatus === "false" ?
                        null 
                        :
                        <svg className={styles.infoIcon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 8V12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 16H12.01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    }
                    {props.alertBodyText}
                    {props.isExternalLinkAvailable ?  <Alert.Link className={styles.linkDeafult} href={props.externalLink} target='_blank'>{props.externalLinkLable} <i class="fas fa-external-link-alt"></i></Alert.Link>:''}
                </Alert>
            }

        </div>
    )
}

CustomAlertBox.defaultProps = {
    alertType: "defaultType",
    toolTipType:"dark",
    isLinkAvailable:false,
    isCancelable:false,
    variant:"primary"
}