import React from 'react';
import styles from './response.module.scss'


export default function OfferAccepted() {

    return (

        <div >
            <div className={styles.container}>
                <p className={styles.content}>
                    You have already submitted your response to the job offer.
                </p>

            </div>

        </div>


    )
}
