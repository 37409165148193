import React from 'react';
import styles from './customButton.module.scss';
import { Button } from 'react-bootstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip'

export const CustomButton = (props) => {

    if (!props.showToolTip) {
        return (
            <Button
                id={props.id}
                name={props.name}
                type={props.buttonType}
                disabled={props.disabled}
                className={` 
                    ${props.type === 'primaryBtn' ? styles.primaryBtn : ''}
                    ${props.type === 'primarySmallBtn' ? styles.primarySmallBtn : ''}
                    ${props.type === 'secondaryBtn' ? styles.secondaryBtn : ''}
                    ${props.type === 'secondarySmallBtn' ? styles.secondarySmallBtn : ''}
                    ${props.type === 'defaultBtn' ? styles.defaultBtn : ''}
                    ${props.type === 'defaultSmallBtn' ? styles.defaultSmallBtn : ''}
                    ${props.type === 'successBtn' ? styles.successBtn : ''}
                    ${props.type === 'successSmallBtn' ? styles.successSmallBtn : ''} 
                    ${props.type === 'warningBtn' ? styles.warningBtn : ''}
                    ${props.type === 'borderSecondaryBtn' ? styles.borderSecondaryBtn : ''}
                    ${props.type === 'dangerBtn' ? styles.dangerBtn : ''}
                    `}
                onClick={props.handleClick}>
                {props.frontIcon && props.frontIcon}
                &nbsp;&nbsp;
                {props.label}
                &nbsp;&nbsp;
                {props.backicon && props.backicon}

            </Button>
        );
    }
    else {
        return (<span className="w-100" data-tip data-for={props.toolTipId}>
            <Button
                id={props.id}
                name={props.name}
                type={props.buttonType}
                disabled={props.disabled}

                className={`
                    ${props.type === 'primaryBtn' ? styles.primaryBtn : ''}
                    ${props.type === 'primarySmallBtn' ? styles.primarySmallBtn : ''}
                    ${props.type === 'secondaryBtn' ? styles.secondaryBtn : ''}
                    ${props.type === 'secondarySmallBtn' ? styles.secondarySmallBtn : ''}
                    ${props.type === 'defaultBtn' ? styles.defaultBtn : ''}
                    ${props.type === 'defaultSmallBtn' ? styles.defaultSmallBtn : ''}
                    ${props.type === 'successBtn' ? styles.successBtn : ''}
                    ${props.type === 'successSmallBtn' ? styles.successSmallBtn : ''} 
                    ${props.type === 'warningBtn' ? styles.warningBtn : ''}
                    ${props.type === 'dangerBtn' ? styles.dangerBtn : ''}
                    `}
                onClick={props.handleClick}>
                {props.frontIcon && props.frontIcon}
                &nbsp;&nbsp;
                {props.label}
                &nbsp;&nbsp;
                {props.backicon && props.backicon}

            </Button>
            <ReactTooltip id={props.toolTipId} type={props.toolTipType === "error" ? "error" : props.toolTipType === "dark" ? "dark" : "dark"}>
                <span>{props.toolTip}</span>
            </ReactTooltip>
        </span>
        )

    }

}


CustomButton.defaultProps = {
    type: "primaryBtn",
    toolTipType: "dark"
}