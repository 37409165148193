import axios from 'axios';
import { axiosInstance } from '../../api';
import { errorHandler } from '../../interceptors';
const BASE_PATH = '/quiz';

export let getQuizDetails = async (quiz_ID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/get_quiz_details', {
            quiz_session_id: quiz_ID
        })
        return value;
    } catch (error) {
        return error
    }
}

export let startQuiz = async (quiz_ID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/start_quiz', {
            quiz_session_id: quiz_ID
        })
        return value;
    } catch (error) {
        return error
    }
}

export let submitQuiz = async (quiz_session_id, question_id, answers, spent_duration) => {

    try {
        let value = await axiosInstance.post(BASE_PATH + '/submit_answer', {
            quiz_session_id: quiz_session_id,
            question_id: question_id,
            spent_duration: spent_duration,
            answer_ids: answers
        })
        return value
    } catch (error) {
        return error
    }
}

export let updateWarning = async (quiz_id) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/update_warning_count', {
            quiz_session_id: quiz_id
        })
        return value
    } catch (error) {
        return error
    }
}

export let submitQuizTimeOver = async (quiz_id) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/submit_quiz_timeover', {
            quiz_session_id: quiz_id
        })
        return value
    } catch (error) {
        return error
    }
}