import React, { useState, useEffect } from 'react'
import { Container, Col, Modal, Button } from 'react-bootstrap';
import OneWayDetails from './oneWayDetails';
import QuestionBody from './questionBody';
import { getQuizDetails } from '../../services/util/quiz';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './interview.module.scss';
import { getOneWayInterviewDetails } from '../../services/util/oneWayInterview';


const OneWayInterview = () => {

    const history = useNavigate();

    let [step, setStep] = useState(1);
    let [primaryColor, setPrimaryColor] = useState();
    let [quizDetails, setQuizDetails] = useState();
    let [isLoading, setLoading] = useState(false);
    let [isError, setIsError] = useState(false);
    let [errorCode, setErrorCode] = useState(null);


    function nextPage(value) {
        setStep(value)
    }

    let { id } = useParams();


    const loadQuizDetails = async () => {
        setLoading(true);
        let respond = await getOneWayInterviewDetails(id);

        if (respond.success == true) {
            if (respond.data.is_one_way_interview_already_submitted) {
                setIsError(true);
                setErrorCode(1);
            }
            else if (respond.data.is_one_way_interview_expired) {
                setIsError(true);
                setErrorCode(2);
            } else {                
                setQuizDetails(respond.data)
                setPrimaryColor(respond.data.primary_color ? respond.data.primary_color : '#24859a')
            }
        } else {
            setLoading(false)
        }

    }


    useEffect(() => {
        loadQuizDetails();
    }, []);

    return (
        <div>

            {
                !isError && step === 1 && <OneWayDetails nextPage={nextPage} data={quizDetails} primaryColor={primaryColor} state={isLoading} refreshData={loadQuizDetails} />
            }
            {
                !isError &&  step === 2 && <QuestionBody id={id} primaryColor={primaryColor} state={isLoading} refreshData={loadQuizDetails} />
            }

            { isError && 
                <div >
                    <div className={styles.container}>
                        {errorCode == 1 && <p className={styles.content}>
                            You have already completed this One-Way Interview.
                        </p>}
                        {errorCode == 2 && <p className={styles.content}>
                            This One-Way Interview is expired or not available.
                        </p>}

                    </div>

                </div>
            }
            

        </div>
    )
}

export default OneWayInterview
