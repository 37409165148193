import React from 'react';
import styles from './customIconButton.module.scss';
import { Button } from 'react-bootstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip'

export const CustomIconButton = (props) => {

    if (!props.showToolTip) {
        return (
            <Button
                id={props.id}
                name={props.name}
                type={props.buttonType}
                disabled={props.disabled}

                className={`
                ${props.type === 'successBtn' ? styles.successBtn : ''}
                ${props.type === 'secondaryBtn' ? styles.secondaryBtn : ''}
                ${props.type === 'warningBtn' ? styles.warningBtn : ''}
                ${props.type === 'dangerBtn' ? styles.dangerBtn : ''}
                ${props.type === 'disabledBtn' ? styles.disabledBtn : ''}
                    `}
                onClick={props.handleClick}>
                {props.icon && props.icon}

            </Button>
        );
    }
    else {
        return (<span data-tooltip-id={props.toolTipId}>
            <Button
                id={props.id}
                name={props.name}
                type={props.buttonType}
                disabled={props.disabled}

                className={`
                ${props.type === 'successBtn' ? styles.successBtn : ''}
                ${props.type === 'secondaryBtn' ? styles.secondaryBtn : ''}
                ${props.type === 'warningBtn' ? styles.warningBtn : ''}
                ${props.type === 'dangerBtn' ? styles.dangerBtn : ''}
                ${props.type === 'disabledBtn' ? styles.disabledBtn : ''}
                    `}
                    onClick={props.handleClick}>
                    {props.icon && props.icon}

            </Button>
            <ReactTooltip id={props.toolTipId} place="bottom" type={props.toolTipType === "error" ? "error" : props.toolTipType === "dark" ? "dark" : "dark"}>
                <span>{props.toolTip}</span>
            </ReactTooltip>
        </span>
        )

    }

}
