import React from 'react';
import styles from './submitted.module.scss'


export default function ReferenceCheckSubmitted(props) {

    return (

        <div >
            <div className={styles.container}>
                <p className={styles.contentSubmitted}>
                    You have already submitted references.
                </p>

            </div>

        </div>


    )
}