import React from 'react';
import styles from './submitted.module.scss'


export default function ReferenceExpired(props) {

    return (

        <div >
            <div className={styles.container}>
                <p className={styles.contentExpired}>
                    Sorry, Reference form has been expired.
                </p>

            </div>

        </div>


    )
}