import React,{useEffect} from 'react';
import CommonErrors from '../shared/commonErrors';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

const NotFound = (props) => {
    const error = "Page Not Found";
    useEffect(() => {
        ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`);
        ReactGA.pageview('/404-Page');
    }, [])
    return (
        <div style={{minHeight:"100vh"}}>
            <Helmet>
                <title>Page Not Found | HireTrace</title>
            </Helmet>
            <CommonErrors 
                alertMessage={error}
            />
        </div>
    )
}

export default NotFound;
