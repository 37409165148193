import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, ProgressBar, Container, Image } from 'react-bootstrap';
import styles from './referenceCheck.module.scss';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Accordion, Button, Form } from 'react-bootstrap';
import CustomDropZone from '../shared/customDropZone';
import { CustomCheckBox } from '../shared/customCheckBox';
import { CustomButton } from '../shared/customButton'
import { Redirect, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getReferenceDetails, submitReference } from '../../services/util/referenceCheck';
import { CustomPopUpBox } from '../shared/customPopUpBox';
import { CustomConfirmationModal } from '../shared/customConfirmationModal';
import { CustomTextField } from '../shared/customTextField';
import { CustomIconButton } from '../shared/customIconButton';
import PhoneInput from 'react-phone-input-2';


export default function ReferenceCheck(props) {

    const history = useNavigate();

    let [referenceDetails, setReferenceDetails] = useState([]);
    let [referenceCount, setReferenceCount] = useState(0);
    let [referenceValues, setReferenceValues] = useState('');
    let [modalActionButtonLoading, setModalActionButtonLoading] = useState(false);
    let [primaryColor, setPrimaryColor] = useState('');
    let [isLoading, setLoading] = useState(false);
    let [accordionIcon, setAccordionIcon] = useState(true);
    let [errorMessage, setErrorMessage] = useState('');

    let { id } = useParams();
    const schema = yup.object({
        referenceName: yup.string().required('Name is required'),
        referenceEmail: yup
            .string()
            .email('Invalid email')
            .required('Email is required')
            .test('unique-email', 'You cannot add references with same email', function(value) {
                return !referenceDetails.some(reference => reference.referenceEmail === value);
            }),
        referencePosition: yup.string().required('Position is required'),
        referenceCompany: yup.string().required('Company / Organization is required'),
        referenceMobileNum: yup
            .string()
            .required('Phone number is required')
            .test('unique-phone', 'You cannot add references with same phone number', function(value) {
                return !referenceDetails.some(reference => reference.referenceMobileNum === value);
            }),
    });


    const loadReferenceCheckDetails = async () => {
        setLoading(true);
        let value = await getReferenceDetails(id);
        if (value.success == true) {
            if (value.data.is_reference_already_submitted) {
                history("/reference-submitted")
            } else if (value.data.is_reference_expired) {
                history("/reference-expired")
            }
            else {
                setReferenceValues(value);
                setPrimaryColor(value.data.primary_color ? value.data.primary_color : '#24859a')
                setLoading(false);
            }

        }

    }

    const changeAccordionIcon = () => {
        setAccordionIcon(!accordionIcon)
    }

    const deleteReference = (index) => {
        // Create a new array based on current state:
        const newArray = [...referenceDetails];
        newArray.splice(index, 1);
        // Update the state with the new array
        setReferenceDetails(newArray);
        setReferenceCount(newArray.length);

        if (newArray.length < referenceValues.data.min_no_of_references_required) {
            setErrorMessage(`Please add at least ${referenceValues.data.min_no_of_references_required - newArray.length} more references`);
        } else {
            setErrorMessage('');
        }
    }

    const addReferenceArray = (values, { resetForm }) => {
        let referenceArray = [...referenceDetails];
        referenceArray.push({
            referenceName: values.referenceName,
            referenceEmail: values.referenceEmail,
            referenceMobileNum: values.referenceMobileNum,
            referencePosition: values.referencePosition,
            referenceCompany: values.referenceCompany
        });
    
        setReferenceDetails(referenceArray);
        setReferenceCount(referenceArray.length);
        resetForm({
            referenceName: '',
            referenceEmail: '',
            referenceMobileNum: '',
            referencePosition: '',
            referenceCompany: ''
        });
        if (referenceArray.length < referenceValues.data.min_no_of_references_required) {
            setErrorMessage(`Please add at least ${referenceValues.data.min_no_of_references_required - referenceArray.length} more references`);
        } else {
            setErrorMessage('');
        }
    }


    const submitConfirmed = async (values) => {
        setModalActionButtonLoading(true);

        if (referenceCount < referenceValues.data.min_no_of_references_required) {
            setErrorMessage(`Please add at least ${referenceValues.data.min_no_of_references_required - referenceCount} more references`);
        } else {
            setLoading(true);
            let response = await submitReference(id, values);
            if (response.success) {
                setLoading(false);
                history("/reference-accepted")
            }
            else {
                setModalActionButtonLoading(false);
                history("/reference-expired")
            }
        }
    }

    useEffect(() => {
        loadReferenceCheckDetails();
    }, []);

    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty(
            "--selected-primary-color", primaryColor
        );
    }, [primaryColor]);


    return (
        <div>
            {!referenceValues ?
                <div>
                    <div className={styles.overlay}></div>
                    <div className={`${styles.spanner} ${styles.show}`}>
                        <div className={styles.loader}></div>
                        <p className={styles.loaderText}>Please Wait...</p>
                    </div>
                </div>
                :
                <div>
                    <Row className={styles.bodyRow}>
                        <Col xs={12} md={6} className={styles.submissionFormView}>
                            <div className={styles.mainContainer}>
                                <Container>
                                    <Col lg={{ span: 10, offset: 1 }}>

                                        <Col className={styles.companySecondaryHeaderBox}></Col>

                                    </Col>
                                    <Col>
                                        <p className={styles.pageTopic}>Submit References</p>
                                    </Col>
                                    <div className={styles.assessmentMainDiv}>
                                        <p className={styles.assessmentMain}>You are required to provide contact details of {referenceValues.data.min_no_of_references_required} referees to progress with your job application. Please read the guidelines carefully and submit the details.</p>
                                    </div>


                                    <Container className={styles.SecContainer}>
                                        <div className='mt-3 ms-3 me-3'>
                                            <Formik
                                                validationSchema={schema}
                                                onSubmit={(values, resetForm) => {
                                                    addReferenceArray(values, resetForm);
                                                }}
                                                initialValues={{}}
                                            >
                                                {({
                                                    errors,
                                                    handleChange,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    submitCount,
                                                    resetForm,
                                                    values
                                                }) => (
                                                    <Form noValidate onSubmit={handleSubmit}>
                                                        <div className='mt-2'>
                                                            <CustomTextField
                                                                label="Name"
                                                                classLabel="defaultLabel"
                                                                controlId="referenceName"
                                                                type="text"
                                                                name="referenceName"
                                                                classType="defaultTextBox"
                                                                handleOnChange={handleChange}
                                                                errorMessage={errors.referenceName}
                                                                placeholder="Enter the name of the referee"
                                                                value={values.referenceName || ''}
                                                                isInvalid={submitCount > 0 && !!errors.referenceName}
                                                            />
                                                        </div>
                                                        <div className='mt-3'>
                                                            <CustomTextField
                                                                label="Email"
                                                                classLabel="defaultLabel"
                                                                controlId="referenceEmail"
                                                                type="email"
                                                                name="referenceEmail"
                                                                classType="defaultTextBox"
                                                                handleOnChange={handleChange}
                                                                errorMessage={errors.referenceEmail}
                                                                placeholder="Enter the email of the referee"
                                                                value={values.referenceEmail || ''}
                                                                isInvalid={submitCount > 0 && !!errors.referenceEmail}
                                                            />
                                                        </div>
                                                        <div className='mt-3'>
                                                            <CustomTextField
                                                                label="Phone Number"
                                                                classLabel="defaultLabel"
                                                                controlId="referenceMobileNum"
                                                                type="text"
                                                                name="referenceMobileNum"
                                                                classType="defaultTextBox"
                                                                handleOnChange={handleChange}
                                                                errorMessage={errors.referenceMobileNum}
                                                                value={values.referenceMobileNum || ''}
                                                                placeholder="Enter a contact number of the referee"
                                                                isInvalid={submitCount > 0 && !!errors.referenceMobileNum}
                                                            />
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Row>
                                                            <Col>
                                                                    <CustomTextField
                                                                        label="Current Company/ Organization"
                                                                        classLabel="defaultLabel"
                                                                        controlId="referenceCompany"
                                                                        type="text"
                                                                        name="referenceCompany"
                                                                        classType="defaultTextBox"
                                                                        placeholder="Enter referee’s current company or organization"
                                                                        handleOnChange={handleChange}
                                                                        value={values.referenceCompany || ''}
                                                                        errorMessage={errors.referenceCompany}
                                                                        isInvalid={submitCount > 0 && !!errors.referenceCompany}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <CustomTextField
                                                                        label="Current Position"
                                                                        classLabel="defaultLabel"
                                                                        controlId="referencePosition"
                                                                        type="text"
                                                                        name="referencePosition"
                                                                        classType="defaultTextBox"
                                                                        placeholder="Enter referee’s current position"
                                                                        value={values.referencePosition || ''}
                                                                        handleOnChange={handleChange}
                                                                        errorMessage={errors.referencePosition}
                                                                        isInvalid={submitCount > 0 && !!errors.referencePosition}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className='mt-4 mb-4'>
                                                            <CustomButton
                                                                buttonType="submit"
                                                                type="borderSecondaryBtn"
                                                                label="Add Reference"


                                                            />
                                                        </div>
                                                        <Row>
                                                            <div>
                                                                <p className={styles.pageGuidlineTopicCount}>References ({referenceCount} / {referenceValues.data.min_no_of_references_required})</p>
                                                            </div>
                                                        </Row>

                                                        <Row>
                                                            <Col>
                                                                <div>
                                                                    {referenceDetails.length > 0 && referenceDetails?.map((reference, index) => (

                                                                        <Accordion defaultActiveKey="0" className={styles.accordianMain}>
                                                                            <Accordion.Item eventKey="0" className={styles.accordianCard} >

                                                                                <Accordion.Header className={styles.cardQuestionHeader} onClick={() => changeAccordionIcon()}>

                                                                                    <div className={styles.accordianBody}>
                                                                                        <div className={styles.headerName}>
                                                                                            <p className={styles.accordianHeaderTopic}>{reference.referenceName} ({reference.referenceEmail})</p>
                                                                                        </div>
                                                                                        <div className={styles.deleteButton}>
                                                                                            <CustomIconButton
                                                                                                type="secondaryBtn"


                                                                                                icon={
                                                                                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M3 6H21" stroke="red" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="red" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path d="M10 11V17" stroke="red" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path d="M14 11V17" stroke="red" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                    </svg>

                                                                                                }
                                                                                                handleClick={() => deleteReference(index)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </Accordion.Header>

                                                                                <Accordion.Body>

                                                                                    <Row key={index}>
                                                                                        <p className={styles.nameTag}>Name: <span className={styles.nameTagValue}>{reference.referenceName}</span></p>
                                                                                        <p className={styles.nameTag}>Email:<span className={styles.nameTagValue}> {reference.referenceEmail}</span></p>
                                                                                        <p className={styles.nameTag}>Mobile Number: <span className={styles.nameTagValue}>{reference.referenceMobileNum}</span></p>
                                                                                        <p className={styles.nameTag}>Position: <span className={styles.nameTagValue}>{reference.referencePosition}</span></p>
                                                                                        <p className={styles.nameTag}>Company / Organization:<span className={styles.nameTagValue}>{reference.referenceCompany}</span></p>
                                                                                    </Row>
                                                                                </Accordion.Body>

                                                                            </Accordion.Item>
                                                                        </Accordion>
                                                                    ))}
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                            <Row className='text-end'>
                                                {errorMessage && (
                                                    <p className={styles.errorMessage}>{errorMessage}</p>
                                                )}
                                            </Row>
                                            <div className="mt-2 mb-3">
                                                <CustomButton
                                                    buttonType="submit"
                                                    label="Submit"
                                                    type="primarySmallBtn"
                                                    disabled={isLoading ? true : false}
                                                    backicon={isLoading === true && <i className="fas fa-circle-notch fa-spin"></i>}
                                                    handleClick={() => { submitConfirmed(referenceDetails) }}
                                                />
                                            </div>
                                            <div className={styles.submitTextCol}>
                                                <p className={styles.signupBottomMessage}>By clicking the &quot;Submit&quot; button, Active fill minimum reference limit.</p>
                                            </div>

                                        </div>
                                    </Container>

                                </Container>
                            </div>

                        </Col>

                        <Col xs={12} md={6} className={styles.descriptionBox}>
                            <Col xs={12} className={styles.descriptionInnerBox}>
                                <Col className={styles.companyPrimaryHeaderBox}>

                                    <Col className="text-center mt-2">
                                        {referenceValues.data?.company_logo_url ?
                                            <Col xs={{ span: 10, offset: 1 }}>
                                                <Image src={referenceValues.data?.company_logo_url} className={styles.companyLogo} alt={referenceValues.data?.company_logo_url + " logo"} />
                                            </Col>
                                            :
                                            <p className={styles.companyName}>{referenceValues.data?.company_name}</p>
                                        }
                                    </Col>
                                    <Col className="mt-3">
                                        <Row className={styles.GuidelinesRow}>
                                            <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                <p className={styles.nameTag}>Candidate Name</p>
                                            </Col>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTagValue}>: {referenceValues.data?.candidate_name} </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className={styles.GuidelinesRow}>
                                            <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                <p className={styles.nameTag}>Position</p>
                                            </Col>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTagValue}>: {referenceValues.data?.hiring_position} </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>

                                <Col className="mt-2">

                                    <Container className={styles.subTopicMainBody}>
                                        <div className={styles.guidlineTopicDiv}>
                                            <div  className={styles.pageGuidlineTopic}> <p>Reference Guidelines</p></div>
                                        </div>
                                        {referenceValues.data.guide_for_candidates ?
                                            <div >
                                                <div className={styles.guidlinecontentMaindiv}>
                                                    <div className={styles.guidelinecontentCol}>1.&nbsp;&nbsp;&nbsp;</div>
                                                    <div className={styles.guidelinecontentColGuidleine}>{referenceValues.data?.guide_for_candidates}</div>
                                                </div>
                                                <div className={styles.guidlinecontentMaindiv}>
                                                    <div className={styles.guidelinecontentCol}>2.&nbsp;&nbsp;</div>
                                                    <div className={styles.guidelinecontentColGuidleine}>Make sure to provide latest contact details of referees.</div>
                                                </div>
                                                <div className={styles.guidlinecontentMaindiv}>
                                                    <div className={styles.guidelinecontentCol}>3.&nbsp;&nbsp;</div>
                                                    <div className={styles.guidelinecontentColGuidleine}>Make sure to inform referees that you submitted their details and they will get contacted by HireTrace or the employer company.</div>
                                                </div>
                                                <div className={styles.guidlinecontentMaindiv}>
                                                    <div className={styles.guidelinecontentCol}>4.&nbsp;&nbsp;</div>
                                                    <div className={styles.guidelinecontentColGuidleine}>To process your job application fast, please submit the references as soon as possible.</div>
                                                </div>

                                            </div> :
                                            <div >
                                                <div className={styles.guidlinecontentMaindiv}>
                                                    <div className={styles.guidelinecontentCol}>1.&nbsp;&nbsp;</div>
                                                    <div className={styles.guidelinecontentColGuidleine}>Make sure to provide latest contact details of referees.</div>
                                                </div>
                                                <div className={styles.guidlinecontentMaindiv}>
                                                    <div className={styles.guidelinecontentCol}>2.&nbsp;&nbsp;</div>
                                                    <div className={styles.guidelinecontentColGuidleine}>Make sure to inform referees that you submitted their details and they will get contacted by HireTrace or the employer company.</div>
                                                </div>
                                                <div className={styles.guidlinecontentMaindiv}>
                                                    <div className={styles.guidelinecontentCol}>3.&nbsp;&nbsp;</div>
                                                    <div className={styles.guidelinecontentColGuidleine}>To process your job application fast, please submit the references as soon as possible.</div>
                                                </div>

                                            </div>
                                        }


                                        <div className={styles.guidlineTopicDivSecond}>

                                            <div className={styles.pageGuidlineTopic}>
                                                <p>Help and Support</p>
                                            </div>

                                        </div>
                                        <Row className='mt-4 ml-1'>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.Guidelines}>
                                                    <p className={styles.guidelinecontentCol}>If you have any questions regarding the reference requirements, contact the employer via <a href={`mailto:${referenceValues.data?.contact_email}`} className={styles.guidelineLinks}>{referenceValues.data?.contact_email}</a>.</p>
                                                </div>
                                            </div>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.Guidelines}>
                                                    <p className={styles.guidelinecontentCol}>If you face any issues submitting references, please contact HireTrace via <a href={`mailto:support@hiretrace.io`} className={styles.guidelineLinks}>support@hiretrace.io</a>.</p>
                                                </div>
                                            </div>

                                        </Row>
                                    </Container>
                                </Col>

                                <Row className='text-center mt-5'>
                                    <Col className={styles.footerCreditBox}>
                                        <p>Powered By <span> <a className={styles.footerCreditLink} href="https://hiretrace.io" rel="noreferrer" target="_blank"> HireTrace</a></span></p>
                                    </Col>
                                </Row>

                            </Col>


                        </Col>



                    </Row>

                </div>
            }
        </div>
    )




}