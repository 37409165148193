import React from 'react';
import styles from './response.module.scss'


export default function AssessmentAccepted() {

    return (

        <div >
            <div className={styles.container}>
                <p className={styles.content}>
                    You have successfully completed and
                    submitted the assignment.
                </p>

            </div>

        </div>


    )
}
