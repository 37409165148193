import React, { useState, useEffect } from 'react';
import { Row, Col, ProgressBar, Container, Image, Button, Alert } from 'react-bootstrap';
import styles from './onewaydetails.module.scss';
import { useNavigate } from "react-router-dom";
import { getOneWayInterviewDetails } from '../../../services/util/oneWayInterview';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import ReactGA from 'react-ga';
import { CustomButton } from '../../shared/customButton'
import { CustomDropdownConfirmationBox } from '../../shared/customDropDownConfirmationBox';
import { CustomPopUpBox } from '../../shared/customPopUpBox';
import { CustomMessagePopUpBox } from '../../shared/customMessagePopUpBox';
import { CustomAlertBox } from '../../shared/customAlertBox';
import Countdown from 'react-countdown';
import { Helmet } from "react-helmet";
import { candidateInterviewRejectionReasonsArray } from './responseArray';
import { CountdownCircleTimer } from "react-countdown-circle-timer";


const OneWayInterviewDetails = (props) => {
    let { id } = useParams();

    //getting query parameter value
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let isStarted = (params.get('is_started') == 'null') ? null : params.get('is_started');

    let [primaryColor, setPrimaryColor] = useState('');
    let [oneWayinterviewDetails, setOneWayInterviewDetails] = useState();
    let [overallTime, setOverallTime] = useState(null);

    const [loadingDeviceTest, setLoadingDeviceTest] = useState();
    const [loadingJoinInterview, setLoadingJoinInterview] = useState();
    const [deviceAccessError, setDeviceAccessError] = useState();
    const [showDeviceAccessErrorPopUp, setShowDeviceAccessErrorPopUp] = useState(false);
    const [showDeviceAccessSuccessPopUp, setShowDeviceAccessSuccessPopUp] = useState(false);
    let [startCount, setStartCount] = useState(false);
    const timeZoneOptions = { timeZoneName: 'long' };
    const userTimeZone = Intl.DateTimeFormat(undefined, timeZoneOptions).resolvedOptions().timeZone;
    // constraints object to check video/audio permissions in browser
    const constraints = window.constraints = {
        audio: true,
        video: true
    };

    // check if browser permissions enabled to access camera and mic
    const CheckDeviceAccess = (check_and_join_meeting) => {

        if (check_and_join_meeting) {
            setLoadingJoinInterview(true);
        }
        else {
            setLoadingDeviceTest(true);
        }
        navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
            // if user clicked Join interview option
            if (check_and_join_meeting) {
                startQuestions();
            } else { //else, just show the permission ok message
                setShowDeviceAccessSuccessPopUp(true)
                setLoadingDeviceTest(false);
            }

        }).catch(function (err) {
            if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {
                setDeviceAccessError("Camera or Microphone not found");
                setShowDeviceAccessErrorPopUp(true);
            } else if (err.name == "NotReadableError" || err.name == "TrackStartError") {
                setDeviceAccessError("Microphone or Camera already in use by a different application ");
                setShowDeviceAccessErrorPopUp(true);
            } else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {
                setDeviceAccessError("Microphone or Camera permissions denied in browser ");
                setShowDeviceAccessErrorPopUp(true);
            } else {
                setDeviceAccessError("Failed to connect with Microphone or Camera. Please try again later");
                setShowDeviceAccessErrorPopUp(true);
            }
            setLoadingDeviceTest(false);
        });
    }

    const closeDeviceAccessTestPopups = () => {
        setShowDeviceAccessSuccessPopUp(false);
        setShowDeviceAccessErrorPopUp(false);
        setLoadingJoinInterview(false);
        setDeviceAccessError(null)
    }
    const loadOneWayInterviewUserDetails = async () => {
        let value = await getOneWayInterviewDetails(id);
        if (value.success === true) {
            let count = 0;
            setOneWayInterviewDetails(value.data)
            for (let x in value.data.one_way_interview_details?.questions) {
                count += value.data.one_way_interview_details?.questions[x].duration;
            }
            setOverallTime(count);
            setPrimaryColor(value.data.primary_color ? value.data.primary_color : '#24859a')
        }
    }

    function onCountChange() {
        props.nextPage(2)
    }
    function startPreviewQuestions() {
        setStartCount(true);
    }
    function startQuestions() {
        props.nextPage(2)
    }
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            return <div className={styles.timer}>Starting..</div>;
        }

        return (
            <div className={styles.timer}>
                <div className={styles.text}>Remaining</div>
                <div className={styles.value}>{remainingTime}</div>
                <div className={styles.text}>seconds</div>
            </div>
        );
    };

    const setCountDownTime = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            //loadQuizDetails();
            props.refreshData();
        } else {
            // Render a countdown
            if (days) {
                return (
                    <span>
                        {days} days  {hours} hrs
                    </span>
                );
            } else if (hours) {
                return (
                    <span>
                        {hours} hrs {minutes} min
                    </span>
                );
            }
            else if (minutes) {
                return (
                    <span>
                        {minutes} min {seconds} sec
                    </span>
                );
            } else {
                return (
                    <span>
                        {seconds} sec
                    </span>
                );
            }

        }
    };

    useEffect(() => {
        loadOneWayInterviewUserDetails();
    }, [])

    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty(
            "--selected-primary-color", primaryColor
        );
        let pageHit = `/app/candidate/one-way-interview`;
        ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`);
        ReactGA.pageview(pageHit);
    }, [primaryColor]);

    return (
        <div>
            <Helmet>
                <title>Candidate Interview | HireTrace</title>
            </Helmet>


            {/* device access error popup */}
            <CustomMessagePopUpBox
                show={showDeviceAccessErrorPopUp}
                cancelIconclick={() => { closeDeviceAccessTestPopups() }}
                onPrimaryBtnClick={() => { closeDeviceAccessTestPopups() }}
                topic="Test Failed"
                message={deviceAccessError}
                primaryBtnName="submitBtn"
                primaryBtnType="button"
                primaryBtnCssType="dangerBtn"
                primaryBtnLabel="Ok!"
                isExternalLinkAvailable={true}
                externalLinkLable="Learn how to enable permissions."
                externalLink="https://hiretrace.io/documentation/interviews/browser-permissions"
            />
            {/* device access success popup */}
            <CustomMessagePopUpBox
                show={showDeviceAccessSuccessPopUp}
                cancelIconclick={() => { closeDeviceAccessTestPopups() }}
                onPrimaryBtnClick={() => { closeDeviceAccessTestPopups() }}
                topic="Test Successful"
                message="No issues detected. Both Microphone and Camera work properly."
                primaryBtnName="submitBtn"
                primaryBtnType="button"
                primaryBtnCssType="successBtn"
                primaryBtnLabel="Ok!"
                is_a_success_message={true}
            />

            <div>
                {!oneWayinterviewDetails ?
                    <div>
                        <div className={styles.overlay}></div>
                        <div className={`${styles.spanner} ${styles.show}`}>
                            <div className={styles.loader}></div>
                            <p className={styles.loaderText}>Please Wait...</p>
                        </div>
                    </div>
                    :
                    <div>

                        <Row className={styles.bodyRow}>
                            <Col xs={12} md={6} className={styles.submissionFormView}>
                                <Container className={styles.mainContainer}>
                                    <Col lg={{ span: 10, offset: 1 }}>
                                        
                                        <Col className={styles.companySecondaryHeaderBox}>

                                        </Col>
                                        <Col className="text-end mb-3 mt-1">
                                            <div className={styles.timeleftMain}> <Countdown date={Date.now() + oneWayinterviewDetails?.remaining_days_before_expiration} renderer={setCountDownTime} /> left</div>
                                        </Col>
                                        <Col>
                                            <p className={styles.pageTopic}>One-way interview</p>
                                        </Col>

                                        <div className={styles.assessmentContent}>
                                            {/* <p className=''>You have been invited to a job interview from {oneWayinterviewDetails.company_name}</p> */}
                                            <p className={styles.assessmentContentHeader}>You have been invited to participate in a One-way Interview. Please read the guidelines and do it and submit your answers.</p>
                                        </div>
                                        <div className={styles.hrDiv}></div>
                                        
                                        <Col className='mt-3'>
                                            <Row className={styles.Guidelines}>
                                                <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTag}>Interview Type</p>
                                                </Col>
                                                <Col className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTagValue}>: {(oneWayinterviewDetails.interview_type === 1) ? 'In-person Interview' : 'One-way Interview'}</p>
                                                </Col>
                                            </Row>
                                           
                                        </Col>
                                        <Col >
                                        <Row className={styles.Guidelines}>
                                                <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTag}>Questions</p>
                                                </Col>
                                                <Col className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTagValue}>: {oneWayinterviewDetails.one_way_interview_details?.questions.length < 10 ? "0" + oneWayinterviewDetails.one_way_interview_details?.questions.length : oneWayinterviewDetails.one_way_interview_details?.questions.length} questions</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col >
                                        <Row className={styles.Guidelines}>
                                                <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTag}>Max. attempts</p>
                                                </Col>
                                                <Col className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTagValue}>: {oneWayinterviewDetails.max_attempts_per_ques?<span>{`0${oneWayinterviewDetails.max_attempts_per_ques} per question`} </span>: 'N/A'} </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        
                                        <Col >
                                            <Row className={styles.Guidelines}>
                                                <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTag}>Avg.  Duration</p>
                                                </Col>
                                                <Col className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTagValue}>: {overallTime} min </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className={styles.detailsBottomCol}>
                                            <Row className={styles.Guidelines}>
                                                <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTag}>Deadline</p>
                                                </Col>
                                                <Col className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTagValue}>: {moment(oneWayinterviewDetails.one_way_interview_expiration_date).format('MMMM Do YYYY, h:mm:ss a')}&nbsp;({userTimeZone})</p>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <div className={styles.hrDiv}></div>
                                          <Col className="mt-4 p-0">
                                        <CustomAlertBox
                                                        alertType = "defaultAlert"
                                                        alertBodyText='Make sure check your audio, video, lighting, internet connection and background environment before start the interview.'
                                                    />
                                                    </Col>
                                        <div>
                                            <div>
                                                <Col className={styles.btnStatetCol}>
                                                    <Row className={styles.controlButtonsRow}>
                                                        <Col sm={6} xs={6} className={styles.bottomButtonLeftCol}>
                                                            <CustomButton
                                                                buttonType="submit"
                                                                label="Test Audio/Video"
                                                                type="defaultBtn"
                                                                handleClick={() => CheckDeviceAccess(false)}
                                                                backicon={(loadingDeviceTest) ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                                                                disabled={loadingJoinInterview || loadingDeviceTest}
                                                            />
                                                        </Col>
                                                        <Col sm={6} xs={6} className={styles.bottomButtonRightCol}>
                                                            <CustomButton
                                                                buttonType="submit"
                                                                label="Start Interview"
                                                                type="primaryBtn"
                                                                handleClick={() => CheckDeviceAccess(true)}
                                                                backicon={(loadingJoinInterview) ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                                                                disabled={loadingJoinInterview || loadingDeviceTest}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <div className={styles.submitTextCol}>
                                                        <p className={styles.signupBottomMessage}>By clicking the&quot;Start Interview&quot; button, you&lsquo;re agreeing to accept</p>
                                                        <p className={styles.termsPara}><a href="https://hiretrace.io/privacy-policy" className={styles.termsLink} target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://hiretrace.io/terms-and-conditions" className={styles.termsLink} target="_blank" rel="noreferrer">Terms of Use.</a></p>
                                                    </div>
                                                </Col>

                                            </div>
                                        </div>



                                    </Col>
                                </Container>
                            </Col>

                            <Col xs={12} md={6} className={styles.descriptionBox}>
                                <Col xs={12} className={styles.descriptionInnerBox}>
                                    <Col className={styles.companyPrimaryHeaderBox}>
                                    <Col className="text-end mb-3">
                                            <div className={styles.timeleft}> <Countdown date={Date.now() + oneWayinterviewDetails?.remaining_days_before_expiration} renderer={setCountDownTime} /> left</div>
                                        </Col>
                                        <Col className="text-center">
                                            {oneWayinterviewDetails.company_logo_url ?
                                                <Col xs={{ span: 10, offset: 1 }}>
                                                    <Image src={oneWayinterviewDetails.company_logo_url} className={styles.companyLogo} alt={oneWayinterviewDetails.company_name + " logo"} />
                                                </Col>
                                                :
                                                <p className={styles.companyName}>{oneWayinterviewDetails.company_name}</p>
                                            }

                                        </Col>
                                        <Col className="mt-3">
                                            <Row className={styles.Guidelines}>
                                                <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTag}>Candidate Name</p>
                                                </Col>
                                                <Col className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTagValue}>: {oneWayinterviewDetails?.candidate_name}</p>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col>
                                            <Row className={styles.Guidelines}>
                                                <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTag}>Position</p>
                                                </Col>
                                                <Col className={styles.guidelineFistcol}>
                                                    <p className={styles.nameTagValue}>: {oneWayinterviewDetails.hiring_position}</p>
                                                </Col>
                                            </Row>
                                        </Col>


                                        <Col className="mt-2">
                                            <Row className={styles.importantTag}>
                                                <div  className={styles.pageGuidlineTopic}><p>Interview Guideline</p></div>
                                            </Row>

                                        </Col>
                                    </Col>

                                    <Col >

                                        {/* video interview via HireTrace  */}
                                        <Container className={styles.subTopicMainBody}>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>1.&nbsp;&nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>Complete the one-way interview before the deadline.</div>
                                            </div>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>2. &nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>Use a laptop or a desktop computer with a camera to take the interview and make sure to have a stable internet connection.</div>
                                            </div>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>3.&nbsp;&nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>Make sure to check your audio, video, lighting, internet connection and background before starting the interview.</div>
                                            </div>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>4.&nbsp;&nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>You can’t skip questions, you have to answer them one by one.</div>
                                            </div>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>5.&nbsp;&nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>You are allowed to take maximum of 3 attempts for each question before submitting your final answer.</div>
                                            </div>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>6.&nbsp;&nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>After starting the interview, do not go outside the browser tab. If you do so, you will get disqualify.</div>
                                            </div>



                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.importantTag}>
                                                <div className={styles.pageGuidlineTopic}><p>Important</p></div>
                                                </div>

                                            </div>
                                            <Row className='ml-1 mt-3'>
                                                <div className={styles.guidlinecontentdiv}>
                                                    <div className={styles.Guidelines}>
                                                        <div className={styles.guidlinecontentdiv}>
                                                            <div className={styles.guidelinecontentCol}>If you have any questions related to the interview, please reach to employer via <a href={`mailto:${oneWayinterviewDetails.contact_email}`} className={styles.guidelineLinks}>{oneWayinterviewDetails.contact_email}.</a></div>
                                                        </div>
                                                        <div className={styles.guidlinecontentdiv}>
                                                            <div className={styles.guidelinecontentCol}>If you face any technical issues, please reach to <a href={`mailto:support@hiretrace.io`} className={styles.guidelineLinks}>support@hiretrace.io</a>.</div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </Row>
                                        </Container>

                                    </Col>

                                    <Row className='text-center mt-5'>
                                        <Col className={styles.footerCreditBox}>
                                            <p>Powered By <span> <a className={styles.footerCreditLink} href="https://hiretrace.io" rel="noreferrer" target="_blank"> HireTrace</a></span></p>
                                        </Col>
                                    </Row>

                                </Col>
                            </Col>
                        </Row>
                    </div>
                }
            </div>

        </div>
    )
}

export default OneWayInterviewDetails