import { axiosInstance } from '../../api';
const TOKEN_KEY = 'isLoggedIn';

//signIn API Call
export let signin = async (email, password) => {
    try {
        let value = await axiosInstance.post('/signin', {
            email: email,
            password: password,
        })

        if (value.success === true){
            setAccessToken(value.data.token)
            setAccountStatus(value.data.activated)
            localStorage.setItem('name', value.data.userName);
            localStorage.setItem('userId', value.data.userId);
            localStorage.setItem('companyName', value.data.companyName);
            localStorage.setItem('Permissions', JSON.stringify(value.data.permissions));
            localStorage.setItem(TOKEN_KEY,true);
            localStorage.setItem('paymentStatus', JSON.stringify(value.data.is_payment_overdue));
        }
        return value;
    } catch (error) {
        return error
    }
}

//signUp API Call
export let signUp = async (email, password,companyName) => {
    try {
        let value = await axiosInstance.post('/company_signup', {
            email: email,
            password: password,
            company_name: companyName,
        })
        return value;
    } catch (error) {
        return error
    }
}

//signUp New User API Call
export let signUpNewUser = async (userId,firstName, lastName, password) => {
    try {
        let value = await axiosInstance.post('/join_new_user', {
            user_id: userId,
            first_name: firstName,
            last_name: lastName,
            password: password
        })
        return value;
    } catch (error) {
        return error
    }
}

//Forgot Password Email API Call
export let forgotPasswordEmail = async (email) => {
    try {
        let value = await axiosInstance.post('/send_password_reset_email', {
            email: email,
        })
        return value;
    } catch (error) {
        return error
    }
}

//Reset Password API Call (Forgot Password)
export let resetPassword = async (email, password,code) => {
    try {
        let value = await axiosInstance.post('/reset_password', {
            email: email,
            new_password:password,
            code: code,
        })
        return value;
    } catch (error) {
        return error
    }
}

//Send Email verification Token For Activation
export let verifyVerificationCode = async (code) => {
    try {
        let value = await axiosInstance.post('/email_verification_for_activation', {
            code: code,
        })
        return value;
    } catch (error) {
        return error
    }
}

//Send Email verification Token For Activation
export let resendVerificationCode = async (code) => {
    try {
        let value = await axiosInstance.post('/send_email_verification_for_activation', {
            code: code,
        })
        return value;
    } catch (error) {
        return error
    }
}



//set Access Token
export let setAccessToken = (value) => {
    return localStorage.setItem('token',value);
}

//get Access Token 
export let getAccessToken = () => {
    return localStorage.getItem('token');
}

//get User Id 
export let getUserId = () => {
    return localStorage.getItem('userId');
}

//set Account Status
export let setAccountStatus = (value) => {
    return localStorage.setItem('activated', value);
}

//get Account Status
export let getAccountStatus = () => {
    return localStorage.getItem('activated');
}

//delete Access Token
export let logout = () => {
    return localStorage.clear();
}


//Check user is logged in function
export let isLogin = () => {
    return localStorage.getItem(TOKEN_KEY) ? true : false;
}

//get email address
export let getEmailAddress = () => {
    return localStorage.getItem('email');
}
