import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import styles from './commonErrors.module.scss';

const CommonErrors = (props) => {
    return (
        <div className={styles.commonErrorPage}>
            <Container>
                <Col xs={{ span: 8, offset: 2 }} sm={{ span: 4, offset: 4 }} className={styles.companyLogo}>
                    <a href="/" target="_blank" rel="noreferrer">
                        <Image src={`${process.env.REACT_APP_FRONTEND_BASE_URL}/images/companylogo.png`} fluid />
                    </a>
                </Col>
                <Row className={styles.bodyRow}>
                    <Col>
                        <Col sm={12}>
                            <h2 className={styles.warningLabel}>Error!</h2>
                            <h5 className={styles.errorType}>{props.alertMessage}

                            </h5>
                        </Col>
                        <Col sm={12} className="mb-3">
                            
                        </Col>
                    </Col>
                    <Col>
                        <Col sm={12} className={styles.errorImage}>
                            <Col>
                                <Image src={`${process.env.REACT_APP_FRONTEND_BASE_URL}/images/warning.svg`} fluid />
                            </Col>
                        </Col>
                    </Col>
                </Row>

            </Container>
        </div >
    )
}

export default CommonErrors;
