import React from 'react';
import styles from './customModalIcon.module.scss';

export const CustomModalIcon = (props) => {



    return (
        <div 
            className={`
                ${props.type === 'defaultIcon' ? styles.defaultIcon : ''}
                ${props.type === 'successIcon' ? styles.successIcon : ''}
                ${props.type === 'warningIcon' ? styles.warningIcon : ''}
                ${props.type === 'dangerIcon' ? styles.dangerIcon : ''}
                ${props.type === 'squareOutlinedIcon' ? styles.squareOutlinedIcon : ''}
            `}
        >
         {props.icon && props.icon}      
        </div>
    )
}
