import React, { useState, useEffect } from 'react';
import { Row, Col, ProgressBar, Container, Image, Accordion } from 'react-bootstrap';
import styles from './jobOffer.module.scss';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import CustomDropZone from '../shared/customDropZone';
import { CustomButton } from '../shared/customButton'
import { Redirect, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getJobOfferDetails } from '../../services/util/jobOffer';
import { CustomAlertBox } from '../shared/customAlertBox';
import { CustomConfirmationWithCheckPopUpBox } from '../shared/customConfirmationWithCheckPopUpBox';
import { CustomConfirmationBox } from '../shared/customConfirmationBox';
import { getUploadedOfferDocumentURL,getPresignedUrlToViewOfferDocuments, uploadOfferDocument, submitAcceptedOfferDocument, submitDeclinedJobOfferDetails } from '../../services/util/jobOffer';
import { toast } from 'react-toastify';


export default function JobOffer(props) {

    const history = useNavigate();
    let [jobOfferDetails, setJobOfferDetails] = useState('');
    let [modalActionButtonLoading, setModalActionButtonLoading] = useState(false);
    let [SubmitData, setSubmitData] = useState();
    let [primaryColor, setPrimaryColor] = useState('');

    const [showAcceptJobOfferConfirmationPopUp, setShowAcceptJobOfferConfirmationPopUp] = useState(false);
    const [showDeclineJobOfferConfirmationPopUp, setShowDeclineJobOfferConfirmationPopUp] = useState(false);
    const [documents, setDocuments] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [submitAcceptedOfferDocuments, setSubmitAcceptedOfferDocuments] = useState([]);
    const [clonesubmitAcceptedOfferDocuments, setCloneSubmitAcceptedOfferDocuments] = useState([]);
    let [accordionIcon, setAccordionIcon] = useState(true);


    let { id } = useParams();


    const loadJobOfferDetails = async () => {
        let value = await getJobOfferDetails(id);
        setDocuments(value.data.documents)
        if (value.success == true) {
            if (value.data.is_already_responded) {
                history("/job-offer-submitted")
            }
            else if (value.data.is_offer_expired_or_invalid) {
                history("/job-offer-expired")
            }
            else if (value.data.is_retracted) {
                history("/job-offer-retracted")
            }
            else if (value.data.is_company_marked_offer_declined) {
                // company marked as job offer declined
                history("/job-offer-declined")
            }
            else {
                setJobOfferDetails(value.data);
                setPrimaryColor(value.data.primary_color ? value.data.primary_color : '#24859a')
            }

        }

    }

// Validation Schema
const schema = yup.object({
    documents: yup.array().of(
        yup.object({
            is_sign_required: yup.boolean(),
            document_is_signed: yup.boolean().test(
                function(value) {
                    const { is_sign_required } = this.parent;
                    return is_sign_required ? value === true : true;
                }
            ),
        })
    )
});
    
    
const onSubmit = async (values) => {
        // Check if all required documents have corresponding uploaded files
        const hasMissingUploads = values.documents.some((doc, index) => {
            if (doc.type === 10 && doc.document_is_signed && doc.is_sign_required) {
                if (!values.uploadedFile[index]) {
                    // Display an error if a required file is missing
                    toast.error(
                        <div>{`You need to upload a signed file to ${doc.name}`}</div>,
                        {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                    return true; // Indicates that there is a missing upload
                }
            }
            return false; // No issue with this document
        });
    
        if (hasMissingUploads) {
            // If there are missing uploads, do not proceed with form submission
            return;
        }
    
        // Proceed with form submission
        setSubmitData(values);
        setShowDeclineJobOfferConfirmationPopUp(false);
        setShowAcceptJobOfferConfirmationPopUp(true);
    };
    

   
    //method t open offer declne confirmation modal
    const toggleDeclineOfferConfirmationPopup = () => {
        setShowAcceptJobOfferConfirmationPopUp(false);
        setShowDeclineJobOfferConfirmationPopUp(true);

    }


    const submitOfferAcceptConfirmed = async () => {
        setModalActionButtonLoading(true);
        const files = SubmitData.uploadedFile;
    
        // Clear the documents before processing
        setSubmitAcceptedOfferDocuments([]);
    
        const processFiles = async () => {
            const uploadPromises = files.map(async (file, index) => {
                if (file) {
                    const fileType = file && file[0] ? file[0].type.split('/')[1].toLowerCase() : null;
                    const uploadedDocumentURL = await getUploadedOfferDocumentURL(id, index, fileType);
    
                    if (uploadedDocumentURL.success) {
                        const uploadResponse = await uploadOfferDocument(uploadedDocumentURL.data.presigned_url, file[0]);
                        
                          let candidateSignedDocumentName = uploadedDocumentURL.data.candidate_signed_document_name
    
                        if (uploadResponse.status === 200) {
                            return { id, index, fileType,candidateSignedDocumentName};
                        }
                    }
                    return null;
                }
                return null;
            });
    
            const uploadedDocuments = await Promise.all(uploadPromises);
            const validDocuments = uploadedDocuments.filter(doc => doc !== null);
    
            setSubmitAcceptedOfferDocuments(validDocuments);
            return validDocuments;
        };
    
        const validDocuments = await processFiles();
        const allFilesProcessedSuccessfully = await submitAcceptedOfferDocument(id, validDocuments);
    
        if (allFilesProcessedSuccessfully) {
            history("/job-offer-accepted");
        } else {
            history("/job-offer-expired");
        }
    
        setModalActionButtonLoading(false);
    };
    
    

    //Submit decline data
    const submitOfferDeclineConfirmed = async (values) => {
        setModalActionButtonLoading(true);
        let response = await submitDeclinedJobOfferDetails(id, values.reason);
        if (response.success == true) {
            setModalActionButtonLoading(false);
            history("/job-offer-accepted");
        } else {
            setModalActionButtonLoading(false);
        }
    }

    const handleClose = () => {
        setShowAcceptJobOfferConfirmationPopUp(false);
        setShowDeclineJobOfferConfirmationPopUp(false);
    }

    useEffect(() => {
        loadJobOfferDetails();
    }, []);

    //set company selected color as the primary color
    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty(
            "--selected-primary-color", primaryColor
        );
    }, [primaryColor]);

    const changeAccordionIcon = () => {
        setAccordionIcon(!accordionIcon)
    }

    const handleLinkClick = (url) => {
        window.open(url, '_blank');
    };
    const handleDownloadClick = async (index) => {
        const response = await getPresignedUrlToViewOfferDocuments(id, index);
        if(response.success){
            const documentURL = response.data.presigned_url;
            window.open(documentURL, '_blank');
        }

    };


    return (


        <div>
            {!jobOfferDetails ?
                <div>
                    <div className={styles.overlay}></div>
                    <div className={`${styles.spanner} ${styles.show}`}>
                        <div className={styles.loader}></div>
                        <p className={styles.loaderText}>Please Wait...</p>
                    </div>
                </div>
                :
                <div>
                    {/* {modal to open accept job offer modal} */}
                    <CustomConfirmationWithCheckPopUpBox
                        show={showAcceptJobOfferConfirmationPopUp}
                        cancelIconclick={handleClose}
                        onSecondaryBtnClick={handleClose}
                        onFormSubmit={submitOfferAcceptConfirmed}
                        isSecondaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                        isPrimaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                        primaryBtnBackSideIcon={modalActionButtonLoading === true ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                        topic="Are you sure?"
                        message={`You want to accept this job offer from ${jobOfferDetails.company_name}`}
                        secondaryBtnName="backBtn"
                        secondaryBtnType="button"
                        secondaryBtnCssType="defaultBtn"
                        secondaryBtnLabel="Cancel"
                        primaryBtnName="submitBtn"
                        primaryBtnType="submit"
                        primaryBtnCssType="successBtn"
                        primaryBtnLabel="Yes, Accept!"
                        checkboxLabel="I hereby confirm that I understand that my response cannot be changed later."
                        confirmationType='warning'

                    />
                    {/* modal to decine job modal */}
                    <CustomConfirmationBox
                        show={showDeclineJobOfferConfirmationPopUp}
                        cancelIconclick={handleClose}
                        onSecondaryBtnClick={handleClose}
                        onFormSubmit={submitOfferDeclineConfirmed}
                        isSecondaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                        isPrimaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                        primaryBtnBackSideIcon={modalActionButtonLoading === true ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                        topic="Are you sure?"
                        message={`You want to decline this job offer from ${jobOfferDetails.company_name}.`}
                        secondaryBtnName="backBtn"
                        secondaryBtnType="button"
                        secondaryBtnCssType="defaultBtn"
                        secondaryBtnLabel="Cancel"
                        primaryBtnName="submitBtn"
                        primaryBtnType="submit"
                        primaryBtnCssType="primaryBtn"
                        primaryBtnLabel="Yes, Decline!"
                        checkboxLabel="I hereby confirm that I understand that my response cannot be changed later."
                        reasonLabel="true"
                        confirmationType='warning'
                    />
                    <div>

                        <div>
                            <Row className={styles.bodyRow}>
                                <Col xs={12} md={6} className={styles.submissionFormView}>
                                    <Container className={styles.mainContainer}>
                                        <Col lg={{ span: 10, offset: 1 }}>
                                            <Col className={styles.companySecondaryHeaderBox}>

                                            </Col>
                                            <Col>
                                                <p className={styles.pageTopic}>Job Offer Details</p>
                                            </Col>
                                            <div className={styles.assessmentMainDiv}>
                                                <p className={styles.assessmentMain}>You have been selected for this job position. Please read the job offer letter, sign it and submit a softcopy.</p>
                                            </div>
                                            <div className='mt-3'>
                                                <Formik
                                                    validationSchema={schema}
                                                    onSubmit={(values) => onSubmit(values)}
                                                    initialValues={{
                                                        uploadedFile: [],
                                                        documents: documents.map(doc => ({
                                                            document_is_signed: doc.document_is_signed || false,
                                                            ...doc
                                                        }))
                                                    }}
                                                    context={{ documents }}
                                                
                                                    >
                                                    

                                                    {({
                                                        errors,
                                                        handleChange,
                                                        handleSubmit,
                                                        setFieldValue,
                                                        submitCount,
                                                        // resetForm,
                                                        values
                                                    }) => (
                                                        <Form noValidate onSubmit={handleSubmit}>
                                                            <div className={styles.JobTitleDiv} >
                                                                <Col>
                                                                <Row>
                                                                    <Col md={2}><p className={styles.JobTitleHeader}>Job Title</p></Col>
                                                                    <Col><p className={styles.JobTitleValue}>:&nbsp;&nbsp;{jobOfferDetails.job_title}</p></Col>
                                                                </Row>
                                                                </Col>
                                                            </div>
                                                            <Accordion defaultActiveKey="0" className={styles.accordianMain}>
                                                                {documents.map((doc, index) => (
                                                                    <Accordion.Item eventKey={index.toString()} key={index} className={doc.is_sign_required && errors &&!!errors.documents?.[index]?styles.accordianCardError: styles.accordianCard}>
                                                                        <Accordion.Header 
                                                                            className={styles.cardHeader}
                                                                            onClick={() => changeAccordionIcon()}
                                                                        >
                                                                            <div className={styles.Accordionheader} >
                                                                                <div className={styles.documentNumber}>{index+1}</div>
                                                                                <div className={styles.headerName}>
                                                                                    <span className={styles.accordianHeaderTopic}>{doc.name}</span>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>

                                                                        <Accordion.Body className={styles.accordianBody} >
                                                                            {doc.type === 20 ? (
                                                                                <>
                                                                                    <Col className={styles.guidelineFirstCol}>
                                                                                        <p className={styles.downloadLinkBox} onClick={() => handleLinkClick(doc.external_document_link)}>
                                                                                            <span className={styles.downloadLink}>Access Document:</span>&nbsp;&nbsp;
                                                                                            <span className={styles.downloadLinkLong}>{doc.external_document_link}</span>
                                                                                            &nbsp;&nbsp;&nbsp;
                                                                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M14.875 6.87501L14.875 2.62501M14.875 2.62501H10.625M14.875 2.62501L8.5 9M7.08333 2.625H5.525C4.33489 2.625 3.73983 2.625 3.28527 2.85661C2.88543 3.06034 2.56034 3.38543 2.35661 3.78527C2.125 4.23983 2.125 4.83489 2.125 6.025V11.975C2.125 13.1651 2.125 13.7602 2.35661 14.2147C2.56034 14.6146 2.88543 14.9397 3.28527 15.1434C3.73983 15.375 4.33489 15.375 5.525 15.375H11.475C12.6651 15.375 13.2602 15.375 13.7147 15.1434C14.1146 14.9397 14.4397 14.6146 14.6434 14.2147C14.875 13.7602 14.875 13.1651 14.875 11.975V10.4167" stroke="#2DA6C0" strokeWidth="1.41667" strokeLinecap="round" strokeLinejoin="round" />
                                                                                            </svg>
                                                                                            &nbsp;
                                                                                        </p>
                                                                                        <Col className="mt-4">
                                                                                            <Row className={styles.alertDiv}>
                                                                                                <CustomAlertBox
                                                                                                    alertType="defaultAlert"
                                                                                                    alertBodyText="If you accept the job offer, you are required to sign external digital document signing link offer letter."
                                                                                                />
                                                                                            </Row>
                                                                                        </Col>
                                                                                        {doc.is_sign_required && (
                                                                                            <div>
                                                                                                <Form.Check
                                                                                                    className={styles.defaultCheckBox}
                                                                                                    type="checkbox"
                                                                                                    id={`signed-doc-${index}`}
                                                                                                    label="I confirm that I have properly signed the document using the provided link."
                                                                                                    checked={values.documents[index]?.document_is_signed || false}
                                                                                                    onChange={e => setFieldValue(`documents[${index}].document_is_signed`, e.target.checked)}
                                                                                                    isInvalid={submitCount > 0 && !!errors.documents?.[index]?.document_is_signed}
                                                                                                />
                                                                                                {submitCount > 0 && errors.documents?.[index]?.document_is_signed && (
                                                                                                    <div className="invalid-feedback">
                                                                                                        {errors.documents[index]?.document_is_signed}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </Col>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Col className={styles.guidelineFirstCol}>
                                                                                        <p onClick={() => handleDownloadClick(index)}>
                                                                                            <span className={styles.downloadLink}>Access Document</span>
                                                                                            &nbsp;&nbsp;&nbsp;
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                <g clipPath="url(#clip0_3350_6442)">
                                                                                                    <path d="M6.66666 10.0001L9.99999 13.3334M9.99999 13.3334L13.3333 10.0001M9.99999 13.3334V6.66675M18.3333 10.0001C18.3333 14.6025 14.6024 18.3334 9.99999 18.3334C5.39762 18.3334 1.66666 14.6025 1.66666 10.0001C1.66666 5.39771 5.39762 1.66675 9.99999 1.66675C14.6024 1.66675 18.3333 5.39771 18.3333 10.0001Z" stroke="#2DA6C0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath id="clip0_3350_6442">
                                                                                                        <rect width="20" height="20" fill="white" />
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                            &nbsp;
                                                                                        </p>
                                                                                        <Col className="mt-4">
                                                                                            <Row className={styles.alertDiv}>
                                                                                                <CustomAlertBox
                                                                                                    alertType="defaultAlert"
                                                                                                    alertBodyText="If you accept the job offer, you are required to sign and upload a softcopy of the job offer letter."
                                                                                                />
                                                                                            </Row>
                                                                                        </Col>
                                                                                        <div className={styles.dragAndDropDiv}>
                                                                                            <CustomDropZone
                                                                                                label="Upload signed Offer letter"
                                                                                                miniLabel="(pdf only)"
                                                                                                classLabel="defaultLabel"
                                                                                                isMultiUpload={false}
                                                                                                isImageUpload={false}
                                                                                                isPDFUpload={true}
                                                                                                maxFileSize={5242880}
                                                                                                acceptFileTypes={"application/pdf"}
                                                                                                viewBoxIcon={
                                                                                                    <svg width="62" height="51" viewBox="0 0 62 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M41.3332 34L30.9998 25.5L20.6665 34" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path d="M31 25.5V44.625" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path d="M52.6739 39.0789C55.1936 37.949 57.184 36.161 58.3311 33.9972C59.4782 31.8334 59.7167 29.417 59.0088 27.1294C58.301 24.8417 56.6871 22.8131 54.422 21.3637C52.1569 19.9143 49.3695 19.1267 46.4998 19.1251H43.2448C42.4628 16.6373 41.0054 14.3276 38.9821 12.3698C36.9588 10.4119 34.4223 8.85683 31.5632 7.82144C28.7041 6.78605 25.5969 6.29729 22.4751 6.3919C19.3533 6.48651 16.2982 7.16204 13.5395 8.36769C10.7808 9.57334 8.39021 11.2777 6.54755 13.3528C4.70488 15.4278 3.45806 17.8194 2.90081 20.3479C2.34356 22.8763 2.49038 25.4758 3.33024 27.9508C4.17011 30.4259 5.68115 32.7121 7.74978 34.6376" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                    </svg>
                                                                                                }
                                                                                                uploadFileTopic={'Drag and Drop or Click to select file'}
                                                                                                maximumFileSizeInMBLabel={'5'}
                                                                                                setFieldValue={setFieldValue}
                                                                                                name={`uploadedFile[${index}]`}
                                                                                                errorMessage={submitCount > 0 && errors.uploadedFile}
                                                                                                isInvalid={submitCount > 0 && !!errors.uploadedFile}
                                                                                            />
                                                                                        </div>
                                                                                        
                                                                                        {doc.is_sign_required && (
                                                                                            <div>
                                                                                                <Form.Check
                                                                                                    className={styles.defaultCheckBox}
                                                                                                    type="checkbox"
                                                                                                    id={`signed-doc-${index}`}
                                                                                                    label="I confirm that I have properly signed the document and uploaded here."
                                                                                                    checked={values.documents[index]?.document_is_signed || false}
                                                                                                    onChange={e => setFieldValue(`documents[${index}].document_is_signed`, e.target.checked)}
                                                                                                    isInvalid={submitCount > 0 && !!errors.documents?.[index]?.document_is_signed}
                                                                                                />
                                                                                                {submitCount > 0 && errors.documents?.[index]?.document_is_signed && (
                                                                                                    <div className="invalid-feedback">
                                                                                                        {errors.documents[index]?.document_is_signed}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </Col>
                                                                                </>
                                                                            )}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                ))}
                                                            </Accordion>

                                                            <div className="mt-4">


                                                                <div className={styles.submitButton}>

                                                                    <Row>
                                                                        <Col>
                                                                            <CustomButton
                                                                                buttonType="button"
                                                                                label="Decline Job Offer"
                                                                                type="defaultBtn"
                                                                                frontIcon={<i className="fas fa-times"></i>}
                                                                                handleClick={() => { toggleDeclineOfferConfirmationPopup() }}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <CustomButton
                                                                                buttonType="submit"
                                                                                label="Accept Job Offer"
                                                                                type="primaryBtn"
                                                                                frontIcon={<i className="fas fa-check"></i>}
                                                                            />

                                                                        </Col>
                                                                    </Row>
                                                                </div>

                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>

                                            <div className={styles.submitTextCol}>
                                                <p className={styles.signupBottomMessage}>By clicking the &quot;Accept Job Offer&quot; button, you&lsquo;re agreeing to accept the HireTrace </p>
                                                <p className={styles.termsPara}><a href="https://hiretrace.io/privacy-policy" className={styles.termsLink} target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://hiretrace.io/terms-and-conditions" className={styles.termsLink} target="_blank" rel="noreferrer">Terms of Use.</a></p>
                                            </div>


                                        </Col>
                                    </Container>
                                </Col>

                                <Col xs={12} md={6} className={styles.descriptionBox}>
                                    <Col xs={12} className={styles.descriptionInnerBox}>
                                        <Col className={styles.companyPrimaryHeaderBox}>
                                            <Col className="text-center mt-3 mb-5">
                                                {jobOfferDetails.company_logo_url ?
                                                    <Col xs={{ span: 10, offset: 1 }}>
                                                        <Image src={jobOfferDetails.company_logo_url} className={styles.companyLogo} alt={jobOfferDetails.company_name + " logo"} />
                                                    </Col>
                                                    :
                                                    <p className={styles.companyName}>{jobOfferDetails.company_name}</p>
                                                }
                                            </Col>
                                            <Col className="mt-3">
                                                <Row className={styles.Guidelines}>
                                                    <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Candidate Name</p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue}>: {jobOfferDetails.candidate_name}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className={styles.Guidelines}>
                                                    <p className={styles.nameTagValue}>You are required to submit your response (Accept or Decline) as soon as possible.</p>

                                                </Row>
                                            </Col>
                                        </Col>

                                        <Col >

                                            <Container className={styles.subTopicMainBody}>
                                                <div className={styles.guidlineTopicDiv}>
                                                   <div className={styles.pageGuidlineTopic}><p>Job Offer Guideline</p></div>
                                                </div>
                                                <div className={styles.guidlinecontentMaindiv}>
                                                    <div className={styles.guidelinecontentCol}>1.&nbsp;&nbsp;</div>
                                                    <div className={styles.guidelinecontentCol}>Download the offer letter (check left side).</div>
                                                </div>
                                                <div className={styles.guidlinecontentdiv}>
                                                    <div className={styles.guidelinecontentCol}>2.&nbsp;</div>
                                                    <div className={styles.guidelinecontentCol}> Read and understand the offer letter carefully.</div>
                                                </div>
                                                <div className={styles.guidlinecontentdiv}>
                                                    <div className={styles.guidelinecontentCol}>3.&nbsp;</div>
                                                    <div className={styles.guidelinecontentCol}>If you are willing to accept the job offer, you are required to sign the offer letter and re-upload it in "Upload signed Offer letter" section.
                                                        (You can put a digital signature and upload it or first print it, then sign it, finally scan the letter clearly and upload it.) Then click on "Accept Job Offer".</div>
                                                </div>
                                                <div className={styles.guidlinecontentdiv}>
                                                    <div className={styles.guidelinecontentCol}>4.&nbsp;</div>
                                                    <div className={styles.guidelinecontentCol}>If you are willing to decline the job offer, skip step 3 and click on "Decline Job Offer". You are required to submit a reason as well.</div>
                                                </div>



                                                <div className={styles.guidlineTopicDiv}>

                                                    <div className={styles.pageGuidlineTopic}>
                                                        <p>Important</p>
                                                    </div>

                                                </div>
                                                <Row className='mt-4 ml-1'>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}> Make sure to submit your response as soon as possible. A company might retract the offer if you didn't respond quickly.</div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>If you have any questions related to Job Offer, please reach to <a href={`mailto:${jobOfferDetails.contact_email}`} className={styles.guidelineLinks}>{jobOfferDetails.contact_email}</a>.</div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>If you face any issues during uploading offer letter" or submitting the response, please reach to <a href={`mailto:support@hiretrace.io`} className={styles.guidelineLinks}>support@hiretrace.io</a>.</div>

                                                    </div>
                                                </Row>
                                            </Container>
                                        </Col>

                                        <Row className='text-center mt-5'>
                                            <Col className={styles.footerCreditBox}>
                                                <p>Powered By <span> <a className={styles.footerCreditLink} href="https://hiretrace.io" rel="noreferrer" target="_blank"> HireTrace</a></span></p>
                                            </Col>
                                        </Row>

                                    </Col>


                                </Col>
                            </Row>
                        </div>


                    </div>

                </div>
            }
        </div >

    )
}