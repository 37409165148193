import { Modal, Button, Row, Col, Container } from 'react-bootstrap';
import React from 'react'
import styles from './customConfirmationModal.module.scss';
import { CustomButton } from '../../shared/customButton';
import { CustomModalIcon } from '../../shared/customModalIcon';

// =====================================
// use to get the confirmation (without reasons or checkbox tics)
// =====================================


export const  CustomConfirmationModal = (props) => {
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                keyboard={false}
                centered             
            >
                <Modal.Body className={styles.modalBodyWrapper}>
                    <div className={styles.modalCancelBtnDiv}>
                        <Button className={styles.modalCancelBtn} onClick={props.cancelIconclick}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 4.5L4.5 13.5"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.5 4.5L13.5 13.5"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Button>
                    </div>
                    <Row>
                        <Col>
                        <Row className={styles.modalHeaderIconDiv}>
                                
                                {props.confirmationType=='success' && 
                                    <CustomModalIcon
                                        type="successIcon"
                                        icon={
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        } 
                                    />
                                }
                                {props.confirmationType=='danger' && 
                                <CustomModalIcon
                                    type="dangerIcon"
                                    icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                    } 
                                />
                                    
                                }
                                {props.confirmationType=='warning' && 
                                <CustomModalIcon
                                    type="warningIcon"
                                    icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                    } 
                                />
                                    
                                }
                                {props.confirmationType=='default' && 
                                <CustomModalIcon
                                    type="defaultIcon"
                                    icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                    } 
                                />
                                    
                                }
                            </Row>
                        </Col>
                    </Row>

                    <div>
                        <p className={styles.modalHeaderTopic}>{props.confirmationTopic}</p>
                        <p className={styles.modalHeaderSubTopic}>{props.confirmationMessage}&nbsp; 
                            {props.externalLink && <span  className={styles.modalMsgLink}>
                                <a  target="_blank" href={props.externalLink} >{props.externalLinkLable} 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                        <path d="M11 4.5L11 1.5M11 1.5H7.99999M11 1.5L6.5 6M5.5 1.5H4.4C3.55992 1.5 3.13988 1.5 2.81901 1.66349C2.53677 1.8073 2.3073 2.03677 2.16349 2.31901C2 2.63988 2 3.05992 2 3.9V8.1C2 8.94008 2 9.36012 2.16349 9.68099C2.3073 9.96323 2.53677 10.1927 2.81901 10.3365C3.13988 10.5 3.55992 10.5 4.4 10.5H8.6C9.44008 10.5 9.86012 10.5 10.181 10.3365C10.4632 10.1927 10.6927 9.96323 10.8365 9.68099C11 9.36012 11 8.94008 11 8.1V7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                            </span>}
                        </p>
                    </div>

                            <Row className={styles.modalActionBtnRow}>
                                <Col>
                                    <CustomButton
                                        id={props.secondaryBtnId}
                                        name={props.secondaryBtnName}
                                        buttonType={props.secondaryBtnType}
                                        disabled={props.isSecondaryBtnDisabled}
                                        type='defaultBtn'
                                        label={props.secondaryBtnLabel}
                                        handleClick={props.onSecondaryBtnClick}
                                    />
                                </Col>
                                <Col className="pl-0">
                                    <CustomButton
                                        id={props.primaryBtnId}
                                        name={props.primaryBtnName}
                                        buttonType={props.primaryBtnType}
                                        disabled={props.isPrimaryBtnDisabled}
                                        type={props.confirmationType=='success'? 'successBtn' : props.confirmationType=='danger'? 'dangerBtn' : props.confirmationType=='warning'? 'warningBtn' : 'secondaryBtn'}
                                        label={props.primaryBtnLabel}
                                        handleClick={props.onPrimaryBtnClick}
                                        backicon={props.primaryBtnBackSideIcon}
                                    />
                                </Col>
                            </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}
