import { axiosInstance } from '../../api';
import axios from 'axios';
const BASE_PATH = '/one_way_interview';

//Get single Interview Details API Call
export let getOneWayInterviewDetails = async (sessionID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/get_one_way_interview_details', {
            session_id: sessionID
        })
        return value;
    } catch (error) {
        return error
    }
}

export let startOneWayInterview = async (sessionID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/start_one_way_interview', {
            session_id: sessionID
        })
        return value;
    } catch (error) {
        return error
    }
}

export let submitOneWayInterview = async (session_id, question_id, answers) => {

    try {
        let value = await axiosInstance.post(BASE_PATH + '/submit_one_way_interview', {
            session_id: session_id,
            question_id: question_id,
            answer_ids: answers
        })
        return value
    } catch (error) {
        return error
    }
}
export let getUploadedAnswerURL = async (session_id,question_id, answerVideotype) => {
    try {
        let values = await axiosInstance.post(BASE_PATH + '/get_presigned_urls_for_one_way_interview_uploads', {
            one_way_interview_session_id: session_id,
            question_id: question_id,
            file_extention: answerVideotype
        })
        return values;
    } catch (err) {
        return err
    } 
}

export let uploadOneWayInterviewAnswerVideo = async (UploadedAnswerVedioURL, videoData) => {
    try {

        let value = await axios.put(UploadedAnswerVedioURL, videoData, {
            headers: {
                'Content-Type': videoData.type
            }
        })
        return value;
    } catch (err) {
        return err;

    }
}

export let substractRetakeCount = async (session_id, question_id) => {

    try {
        let value = await axiosInstance.post(BASE_PATH + '/substract_retake_count', {
            session_id: session_id,
            question_id: question_id,
        })
        return value
    } catch (error) {
        return error
    }
}