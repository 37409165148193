import React, { useEffect, useRef, useState } from 'react';
import { useMediaRecorder } from 'react-media-recorder';
import styles from './questionbody.module.scss';
import { CustomButton } from '../../shared/customButton';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Col, Container, Row } from 'react-bootstrap';
import CSSLoader from '../../shared/cssLoader';
import { CustomModalIcon } from '../../shared/customModalIcon'
import { startOneWayInterview, submitOneWayInterview, getUploadedAnswerURL, uploadOneWayInterviewAnswerVideo, substractRetakeCount} from '../../../services/util/oneWayInterview';
import { useReactMediaRecorder, ReactMediaRecorder } from "react-media-recorder";
import { duration } from 'moment-timezone';


const QuestionBody = (props) => {
  let [isQuestionloading, setIsQuestionLoading] = useState(true);
  const [seconds, setSeconds] = useState(null);
  const [newRetakeCount, setNewRetakeCount] = useState(null);
  let [thankyouStatus, setThankyouStatus] = useState(false);
  let [videoData, setVideoData] = useState(null);
  let [endCount, setEndCount] = useState(false);
  let [isPageloading, setIsPageLoading] = useState(true);
  let [questionData, setQuestionData] = useState();
  let [additionalData, setAdditionalData] = useState();
  let [questionSessionId] = useState(props.id);
  let [endQuestionVideo, setEndQuestionVideo] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [blobUrl, setBlobUrl] = useState(null);
  let [stopRecordingStatus, setStopRecordingStatus] = useState(false);
  let [isloading, setIsLoading] = useState(false);
  let [isRetakeloading, setIsRetakeloading] = useState(false);
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({ video: true });

  //starting questions with order
  const startOneWayInterviewWithFirstQuestion = async () => {

    setIsPageLoading(true);

    // get interview details
    let respond = await startOneWayInterview(questionSessionId);

    if (respond.success == true) {
      handleQuestionData(respond.data);
    }
    else {
      window.location.reload();
    }
  }
  //assign each question data
  function handleQuestionData(values) {
    let question = {
      question_text: values.next_question?.question_text,
      question_id: values.next_question?.question_id,
      question_title: values.next_question?.question_title,
      question_name: values.next_question?.question_name,
      question_number: values.next_question?.question_number,
      total_question_count: values.total_question_count,
      duration: values.next_question?.duration,
      retake: values.next_question?.retake,
    }
    let additional = {
      position: values.position,
      company_name: values.company_name,
      is_the_last_question: values.is_the_last_question,
    }
    setNewRetakeCount(values.next_question?.retake);
    setQuestionData(question);
    setSeconds(question?.duration * 60)
    setAdditionalData(additional);
    setIsQuestionLoading(false);
  }

  //circle countdown over action
  function onCountChange() {
    setEndCount(true)
    startRecording();
    setSeconds(questionData?.duration * 60)
  }

  async function retakeAnswer() {
    setStopRecordingStatus(true);
    setIsRetakeloading(true);
    if (newRetakeCount > 1) {
      await substractRetakeCount(questionSessionId,questionData?.question_id)
      let count = newRetakeCount;
      setSeconds(questionData?.duration * 60)
      setNewRetakeCount(count - 1);
      setEndCount(true);
      setIsRetakeloading(false);
      startRecording();
    }

  }

  // manage video ending function
  const handleVideoStop = () => {
    setIsLoading(true);
    stopRecording();
    setIsLoading(false);
  };

  //circle timer time changes preview
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className={styles.timer}>Starting..</div>;
    }

    return (
      <div className={styles.timer}>
        {remainingTime > 60 ?
          <>
            <div className={styles.text}>Start answering in</div>
            <div className={styles.text}>{Math.floor(remainingTime / 60)} minutes  {remainingTime % 60} seconds</div>

          </>
          :
          <>
            <div className={styles.text}>Start answering in</div>
            <div className={styles.value}>{remainingTime}</div>
            <div className={styles.text}>seconds</div>
          </>
        }
      </div>
    );
  };

  //candidate click on "Next" or "Submit & Finish" buttons
  const submitAnswers = async () => {
    let answer = [];
    let type = null;
    let tempVideoData = null;
    const url = mediaBlobUrl;
    if (url) {
      try {
        setIsLoading(true);
        const response = await fetch(mediaBlobUrl);
        const blob = await response.blob();
        const tempVideoData = blob;
        type = blob.type.split('/')[1].toLowerCase();
        let UploadedOneWayInterviewAnswerURL = await getUploadedAnswerURL(
          questionSessionId,
          questionData?.question_id,
          type
        );
        if (UploadedOneWayInterviewAnswerURL.success === true) {
          let response = await uploadOneWayInterviewAnswerVideo(
            UploadedOneWayInterviewAnswerURL.data.presigned_url,
            tempVideoData
          );
          if (response.status === 200) {
            let respond = await submitOneWayInterview(
              questionSessionId,
              questionData?.question_id,
              answer
            );
            if (respond.success === true) {
              if (!respond.data.is_one_way_interview_finished) {
                setIsLoading(false);
                handleQuestionData(respond.data);
                setEndCount(false);
              } else {
                setIsLoading(false);
                setThankyouStatus(true);
              }
            } else {
              // If error, reload to the initial page
              window.location.reload();
            }
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    startOneWayInterviewWithFirstQuestion();
  }, [questionSessionId]);


  function liveStreamWrapper(previewStream, fn, status) {
    if (status !== 'stopped' && previewStream) {
      return fn(previewStream);
    }
    return null; // Return null when the stream is stopped
  }
  
  function liveStream(stream) {
    const previewStream = stream;
    if (previewStream != null) {
      return <VideoPreview stream={previewStream} />
    }
  }
  const VideoPreview = (props) => {
    const stream = props.stream;
    const videoRef = useRef(null);
    const [newSeconds, setNewSeconds] = useState(seconds); // Initialize with your desired initial value
    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
        // Block the pause functionality
        const videoElement = videoRef.current;

        const preventPause = (event) => {
          videoElement.play();
        };

        videoElement.addEventListener('pause', preventPause);

        return () => {
          videoElement.removeEventListener('pause', preventPause);
        };
      }
    }, [stream])
    // Decrement the seconds every second
    useEffect(() => {
      if (newSeconds > 0) {
        const interval = setInterval(() => {
          setNewSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);
  
        return () => {
          clearInterval(interval);
        };
      } else if (newSeconds === 0) {
        stopRecording(); // Call the stopRecording function when seconds reach 0
      }
    }, [newSeconds]);
    if (!stream) {
      return null;
    }
    return (
      <div className={styles.container}>
        <div className={styles.parentContainer} >
          <div className={styles.recordIconDiv}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6 11V13M10 9V15M14 7V17M18 11V13M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#F04438" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p className={styles.recordIconText}>REC</p>
          </div>
          {newSeconds > 60 ? (
            <p className={styles.countDownText}>Remaining time: {Math.floor(newSeconds / 60)} min {newSeconds % 60} sec</p>
          ) : (
            <p className={styles.countDownText}>Remaining time: {newSeconds} sec</p>
          )}
        </div>

        <video id="livePreview" ref={videoRef} className={styles.hidePauseButton} playsInline muted autoPlay controls />
      </div>
    );

  }


  return (
    <div className={styles.mainbody}>
      {isQuestionloading == true ?
        (<div className={styles.questioLoadingDiv}>
          <Col sm={{ span: 4, offset: 4 }}>
            <CSSLoader />
          </Col>
        </div>)
        :
        <>
          {!endCount &&
            <Container className={styles.main_container}>
              <Row>
                <div className={styles.topRow}>
                  <p className={styles.topRowText}>One-way interview</p>
                  <p className={styles.topRowSecondText}>{additionalData.company_name} - {additionalData.position}</p>
                </div>
              </Row>
              <Row >
                <Col lg={{ span: 10, offset: 1 }} className={styles.mainQuestionDetailed}>
                  <div className={styles.questionDetailedHeader}>
                    <p className={styles.questionHeaderText}>{questionData?.question_name}</p>
                    <p className={styles.questionHeaderCountText}>Question {questionData?.question_number} of {questionData?.total_question_count} </p>
                  </div>
                  <div>
                    <p className={styles.questionHeaderSecondText}>{questionData?.question_text}</p>
                  </div>
                  <div className={styles.answerRules}>
                    <p className={styles.introducationRightText}>Maximum answer duration - {questionData?.duration} min</p>
                    <p className={styles.introducationRightText}>Maximum answer take - {questionData?.retake} times</p>
                    <p className={styles.introducationRightText}>Maximum ready time - 3min </p>
                  </div>
                </Col>
              </Row>
              <Row className={styles.mainRow}>
                <Col lg={{ span: 10, offset: 1 }} className={styles.mainQuestionSecondDetailed}>
                  <div className={styles.interviewDescription}>
                    <div className={styles.interviewDescriptionLeft}>
                      <CountdownCircleTimer
                        isPlaying
                        duration={180}
                        colors="#2da6c0"
                        initialRemainingTime={180}
                        onComplete={() => onCountChange()}
                      >
                        {renderTime}
                      </CountdownCircleTimer>
                    </div>
                  </div>
                </Col>
                <Col>
                </Col>
              </Row>
              <Row className={styles.buttonMainDiv}>
                <Col className={styles.leftBottomSeecondButtonMain} md={{ span: 2, offset: 9 }} >
                  <div className={styles.leftBottomSeecondButtonDiv}>
                    <CustomButton
                      buttonType="button"
                      label="Start Answering"
                      type="primaryBtn"
                      handleClick={() => onCountChange()}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          }
          {(endCount && !thankyouStatus) &&
            <Container className={styles.main_container}>
              <Row>
                <div className={styles.topRow}>
                  <p className={styles.topRowText}>One-way interview</p>
                  <p className={styles.topRowSecondText}>{additionalData.company_name} - {additionalData.position}</p>
                </div>
              </Row>
              <Row>
                <Col lg={{ span: 10, offset: 1 }} className={styles.mainQuestionDetailed}>
                  <div className={styles.questionDetailedHeader}>
                    <p className={styles.questionHeaderText}>{questionData?.question_name}</p>
                    <p className={styles.questionHeaderCountText}>Question {questionData?.question_number} of {questionData?.total_question_count} </p>
                  </div>
                  <div>
                    <p className={styles.questionHeaderSecondText}>{questionData?.question_text}</p>
                  </div>
                  <div className={styles.answerRules}>
                    <p className={styles.introducationRightText}>Maximum answer duration - {questionData?.duration} min</p>
                    <p className={styles.introducationRightText}>Maximum answer take - {newRetakeCount - 1} times</p>
                    <p className={styles.introducationRightText}>Maximum ready time - 3min </p>
                  </div>
                </Col>
              </Row>
              <Row className={styles.mainRow}>
                <Col lg={{ span: 10 }} className={styles.mainQuestionSecondDetailed}>
                  <div className={styles.interviewDescription}>
                    {status === 'stopped' &&
                      <>
                        <video src={mediaBlobUrl} controls></video>
                      </>
                    }
                    {status !== 'stopped' && liveStreamWrapper(previewStream, liveStream, status)}
                  </div>
                </Col>
              </Row>
              <Row  className={styles.buttonSecondDiv}>
                <Col className={styles.leftBottomSeecondButtonMain} lg={{ span: 5, offset: 6}} >
                  {status === 'stopped' ?
                    <>
                      <Row className={styles.waitingText}>
                        <Col>
                          {isloading === true &&
                            <p className={styles.uploadingWaitingText}>your answer is uploading please wait a moment.</p>
                          }
                        </Col>
                      </Row>
                      <Row  className={styles.buttonMainDiv}>
                        {newRetakeCount > 1 &&
                          <Col className='mt-2' lg={{ span: 4 }} >
                            <CustomButton
                              buttonType="submit"
                              label="Retake"
                              type="defaultBtn"
                              handleClick={() => { retakeAnswer(); }}
                              disabled={newRetakeCount <= 0 || isloading || isRetakeloading}
                              backicon={isRetakeloading === true && <i className="fas fa-circle-notch fa-spin"></i>}
                            />
                          </Col>
                        }
                        <Col className='mt-2' lg={{ span: newRetakeCount > 1 ? 8 : 8, offset: newRetakeCount > 1 ? 0 : 4}}>
                          <div className={styles.leftBottomMainButtonDiv}>
                            <CustomButton
                              buttonType="submit"
                              label={additionalData.is_the_last_question === true ? "Save and Submit" : "Save and Next"}
                              type="primaryBtn"
                              handleClick={() => { submitAnswers(); }}
                              backicon={isloading === true && <i className="fas fa-circle-notch fa-spin"></i>}
                              disabled={isloading}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                    :
                    <>
                      <Row  className={styles.buttonMainDiv}>
                        <Col className='mt-2' lg={{ span: 5, offset: 7 }}>
                          <div className={styles.leftBottomMainButtonDiv}>
                            <CustomButton
                              buttonType="button"
                              label="Stop Recording"
                              type="primaryBtn"
                              handleClick={() => { handleVideoStop(); }}
                              backicon={isloading === true && <i className="fas fa-circle-notch fa-spin"></i>}
                              disabled={isloading}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  }
                </Col>
              </Row>
            </Container>
          }
          {(endCount && thankyouStatus) &&
            <Container className={styles.main_container}>
              <Row>
                <div className={styles.topRow}>
                  <p className={styles.topRowText}>One-way interview</p>
                  <p className={styles.topRowSecondText}>{additionalData.company_name} - {additionalData.position}</p>
                </div>
              </Row>
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <div className={styles.thankPageDiv}>
                    <CustomModalIcon
                      type="successIcon"
                      icon={
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      }
                    />
                  </div>
                  <div className={styles.thankPageSecondDiv}>
                    <p className={styles.thankPageText}>Thank you participation...</p>
                    <p className={styles.thankPageSecondText}>We have successfully submitted your answers to {additionalData.company_name}. Their hiring team will review your answers shortly. Wish you all the best!</p>
                  </div>
                </Col>
              </Row>
            </Container>

          }
        </>
      }
    </div>

  )
}
export default QuestionBody;