import axios from 'axios';
import { axiosInstance } from '../../api';
import { errorHandler } from '../../interceptors';
const BASE_PATH = '/offer_handling';



export let getJobOfferDetails = async (session_id) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/candidate_get_job_offer_details', {
            job_offer_session_id: session_id
        });
        return value;
    } catch (error) {
        return error
    }
}

export let submitAcceptedOfferDocument = async (sessionID, documents) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/candidate_accept_job_offer_with_signed_offer_letter', {
            job_offer_session_id: sessionID,
            documents: documents,
        })
        return value;
    } catch (error) {
        return error
    }
}

export let getUploadedOfferDocumentURL = async (session_id,index ,filetype) => {
    try {
        let values = await axiosInstance.post(BASE_PATH + '/get_presigned_urls_for_signed_job_offer_uploads', {
            job_offer_session_id: session_id,
            index: index,
            filetype: filetype
        })
        return values;
    } catch (err) {
        return err
    }
}
export let getPresignedUrlToViewOfferDocuments = async (session_id,index ) => {
    try {
        let values = await axiosInstance.post(BASE_PATH + '/get_presigned_url_to_view_offer_documents', {
            job_offer_session_id: session_id,
            index: index,
        })
        return values;
    } catch (err) {
        return err
    }
}


export let uploadOfferDocument = async (offerDocUploadedURL, getfile) => {
    try {

        let formData = new FormData();
        formData.append('file', getfile);
        let value = await axios.put(offerDocUploadedURL, formData, {
            headers: {
                'Content-Type': getfile.type
            }
        })

        return value;
    } catch (err) {
        return err;

    }
}

export let submitDeclinedJobOfferDetails = async (session_id, reason) => {

    try {

        let values = await axiosInstance.post(BASE_PATH + '/candidate_declined_job_offer', {
            job_offer_session_id: session_id,
            reason: reason
        })
        return values;
    } catch (err) {
        return err
    }

}