import { axiosInstance } from '../../api';
const BASE_PATH = '/interview';

//Get All Interviews API Call
export let getAllInterviews = async () => {
    try {
        let value = await axiosInstance.get(BASE_PATH + '/get_assigned_interviews');
        return value;
    } catch (error) {
        return error
    }
}

//Get calendar availability of reviewer API Call
export let checkCalendarAvailability = async (sessionID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/check_calender_availability_for_interview', {
            session_id: sessionID
        })
        return value;
    } catch (error) {
        return error
    }
}

//Get single Interview Details API Call
export let getInterviewDetails = async (sessionID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/get_interview', {
            session_id: sessionID
        })
        return value;
    } catch (error) {
        return error
    }
}


//Join  Interview Interviewer API Call
export let getJoinInterviewersDetails = async (sessionID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/join_interview_by_interviewer', {
            session_id: sessionID
        })
        return value;
    } catch (error) {
        return error
    }
}

//Join Candidate Interview API Call
export let getJoinCandidateDetails = async (sessionID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/join_interview_by_candidate', {
            session_id: sessionID
        })
        return value;
    } catch (error) {
        return error
    }
}

//Submit Interviewer marks API Call
export let submitCandidateReviews = async (sessionID, score, comments) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/submit_interview_review', {
            session_id: sessionID,
            score: score,
            comments: comments
        })
        return value;
    } catch (error) {
        return error
    }
}


//End Interview For all API Call
export let endMeetingForAll = async (sessionID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/end_meeting_for_all', {
            session_id: sessionID
        })
        return value;
    } catch (error) {
        return error
    }
}


//Get Candidate RSVP API Call
export let getCandidateDetailsRSVP = async (sessionID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/get_interview_details', {
            session_id: sessionID
        })
        return value;
    } catch (error) {
        return error
    }
}

//Set Candidate RSVP Status API Call
export let setCandidateRSVP = async (sessionID, rsvp, message) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/set_candidate_rsvp', {
            session_id: sessionID,
            rsvp: rsvp,
            message: message
        })
        return value;
    } catch (error) {
        return error
    }
}

//Get Interviewers RSVP Status API Call
export let getInterviewerRSVP = async (sessionID, rsvp) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/set_interviewer_rsvp', {
            session_id: sessionID,
            rsvp: rsvp
        })
        return value;
    } catch (error) {
        return error
    }
}

//Set Interviewers Interview Status API Call
export let setInterviewerInterviewState = async (sessionID, state) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/set_interviewer_interview_state', {
            session_id: sessionID,
            interview_state: state
        })
        return value;
    } catch (error) {
        return error
    }
}

//Set Candidate Interview Status API Call
export let setCandidateInterviewState = async (sessionID, state) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/set_candidate_interview_state', {
            session_id: sessionID,
            interview_state: state
        })
        return value;
    } catch (error) {
        return error
    }
}

export let candidateGetMeetingEndedReason = async (sessionID) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/candidate_get_meeting_ended_reason', {
            session_id: sessionID
        })
        return value;
    } catch (error) {
        return error
    }
}

export let requestToAdmitInterview = async (sessionID) => {
    
    try {
        let value = await axiosInstance.post(BASE_PATH + '/check_if_admit_approved_by_interviewers', {
            session_id: sessionID
        })
        return value.data;
    } catch (error) {
        return error
    }
}