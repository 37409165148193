import React from 'react';
import { Form } from 'react-bootstrap';
import styles from './customTextField.module.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip'

export const CustomTextField = (props) => {
    return (
        <div>
            {props.isCancelable === true ?
                <Form.Group controlId={props.controlId} className={styles.formGroup}>
                    {props.label &&
                        <Form.Label
                            className={
                                `${props.classLabel === 'defaultLabel' ? styles.defaultLabel : ''}
                                ${props.classLabel === 'disabledLabel' ? styles.disabledLabel : ''}
                            `}>
                            {props.label}
                        </Form.Label>
                    }
                    <Form.Control
                        className={`
                            ${props.classType === 'defaultTextBox' ? styles.defaultTextBox : ''}
                            ${props.classType === 'disabledTextBox' ? styles.disabledTextBox : ''}
                            ${props.classType === 'savableAndCancelableTextBox' ? styles.savableAndCancelableTextBox : ''}
                        `}
                        placeholder={props.placeholder}
                        onChange={props.handleOnChange}
                        name={props.name}
                        type={props.type}
                        isInvalid={props.isInvalid}
                        value={props.value}
                        disabled={props.disabled}
                        maxLength={props.maxlength}
                        pattern={props.pattern}
                        readOnly={props.readOnly}
                        key={props.keyValue}
                        required={props.required}
                    />
                    {props.isInvalid !== true &&
                        <div className={styles.cancelIconDiv} onClick={props.onCancelClick} key={(props.keyValue) + 1}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 4.5L4.5 13.5" stroke="#BDBDBD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.5 4.5L13.5 13.5" stroke="#BDBDBD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    }

                    {props.errorMessage &&
                        <Form.Control.Feedback type="invalid" key={(props.keyValue) + 2} className={`${styles.errorDiv} ${styles.errorMsg}`}>
                            {props.errorMessage}
                        </Form.Control.Feedback>
                    }

                </Form.Group>
                : props.isEditable === true ?
                    <Form.Group controlId={props.controlId} className={styles.formGroup}>
                        {props.label &&
                            <Form.Label
                                className={`
                                    ${props.classLabel === 'defaultLabel' ? styles.defaultLabel : ''}
                                    ${props.classLabel === 'disabledLabel' ? styles.disabledLabel : ''}
                                    ${props.classType === 'savableAndCancelableTextBox' ? styles.savableAndCancelableTextBox : ''}
                                `}>
                                {props.label}
                            </Form.Label>
                        }
                        <Form.Control
                            className={`
                                ${props.classType === 'defaultTextBox' ? styles.defaultTextBox : ''}
                                ${props.classType === 'disabledTextBox' ? styles.disabledTextBox : ''}
                            `}
                            placeholder={props.placeholder}
                            onChange={props.handleOnChange}
                            name={props.name}
                            type={props.type}
                            isInvalid={props.isInvalid}
                            value={props.value}
                            disabled={props.disabled}
                            maxLength={props.maxlength}
                            pattern={props.pattern}
                            readOnly={props.readOnly}
                            key={props.keyValue}
                            required={props.required}
                        />
                        {props.isInvalid !== true &&
                            <div className={styles.editIconDiv} onClick={props.onEditClick}>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.625 1.87519C10.7892 1.71104 10.984 1.58082 11.1985 1.49199C11.413 1.40315 11.6429 1.35742 11.875 1.35742C12.1071 1.35742 12.337 1.40315 12.5515 1.49199C12.766 1.58082 12.9608 1.71104 13.125 1.87519C13.2892 2.03934 13.4194 2.23422 13.5082 2.44869C13.597 2.66317 13.6428 2.89304 13.6428 3.12519C13.6428 3.35734 13.597 3.58721 13.5082 3.80168C13.4194 4.01616 13.2892 4.21104 13.125 4.37519L4.6875 12.8127L1.25 13.7502L2.1875 10.3127L10.625 1.87519Z" stroke="#828282" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        }

                        {props.errorMessage &&
                            <Form.Control.Feedback type="invalid" className={`${styles.errorDiv} ${styles.errorMsg}`}>
                                {props.errorMessage}
                            </Form.Control.Feedback>
                        }

                    </Form.Group>
                : props.isEditableAndToolTipEnabled === true ?
                    <Form.Group controlId={props.controlId} className={styles.formGroup}>
                        {props.label &&
                            <Form.Label
                                className={`
                                    ${props.classLabel === 'defaultLabel' ? styles.defaultLabel : ''}
                                    ${props.classLabel === 'disabledLabel' ? styles.disabledLabel : ''}
                                    ${props.classType === 'savableAndCancelableTextBox' ? styles.savableAndCancelableTextBox : ''}
                                `}>
                                {props.label}
                            </Form.Label>
                        }
                        <Form.Control
                            className={`
                                ${props.classType === 'defaultTextBox' ? styles.defaultTextBox : ''}
                                ${props.classType === 'disabledTextBox' ? styles.disabledTextBox : ''}
                            `}
                            placeholder={props.placeholder}
                            onChange={props.handleOnChange}
                            name={props.name}
                            type={props.type}
                            isInvalid={props.isInvalid}
                            value={props.value}
                            disabled={props.disabled}
                            maxLength={props.maxlength}
                            pattern={props.pattern}
                            readOnly={props.readOnly}
                            key={props.keyValue}
                            required={props.required}
                        />
                        {props.isInvalid !== true ? 

                            props.disabled === true && props.isAdminUser === true ?
                            <div>
                                <div className={styles.editIconDiv} onClick={props.onEditClick} data-tip data-for={props.toolTipId}>
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.625 1.87519C10.7892 1.71104 10.984 1.58082 11.1985 1.49199C11.413 1.40315 11.6429 1.35742 11.875 1.35742C12.1071 1.35742 12.337 1.40315 12.5515 1.49199C12.766 1.58082 12.9608 1.71104 13.125 1.87519C13.2892 2.03934 13.4194 2.23422 13.5082 2.44869C13.597 2.66317 13.6428 2.89304 13.6428 3.12519C13.6428 3.35734 13.597 3.58721 13.5082 3.80168C13.4194 4.01616 13.2892 4.21104 13.125 4.37519L4.6875 12.8127L1.25 13.7502L2.1875 10.3127L10.625 1.87519Z" stroke="#828282" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                    <ReactTooltip id={props.toolTipId} type={props.toolTipType === "error" ? "error" : props.toolTipType === "dark" ? "dark" : "dark"}>
                                        <span>{props.toolTip}</span>
                                    </ReactTooltip>
                            </div>
                                
                            :
                            
                            <div className={styles.editIconDiv} onClick={props.onEditClick}>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.625 1.87519C10.7892 1.71104 10.984 1.58082 11.1985 1.49199C11.413 1.40315 11.6429 1.35742 11.875 1.35742C12.1071 1.35742 12.337 1.40315 12.5515 1.49199C12.766 1.58082 12.9608 1.71104 13.125 1.87519C13.2892 2.03934 13.4194 2.23422 13.5082 2.44869C13.597 2.66317 13.6428 2.89304 13.6428 3.12519C13.6428 3.35734 13.597 3.58721 13.5082 3.80168C13.4194 4.01616 13.2892 4.21104 13.125 4.37519L4.6875 12.8127L1.25 13.7502L2.1875 10.3127L10.625 1.87519Z" stroke="#828282" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            :

                            <div className={styles.editIconDiv} onClick={props.onEditClick}>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.625 1.87519C10.7892 1.71104 10.984 1.58082 11.1985 1.49199C11.413 1.40315 11.6429 1.35742 11.875 1.35742C12.1071 1.35742 12.337 1.40315 12.5515 1.49199C12.766 1.58082 12.9608 1.71104 13.125 1.87519C13.2892 2.03934 13.4194 2.23422 13.5082 2.44869C13.597 2.66317 13.6428 2.89304 13.6428 3.12519C13.6428 3.35734 13.597 3.58721 13.5082 3.80168C13.4194 4.01616 13.2892 4.21104 13.125 4.37519L4.6875 12.8127L1.25 13.7502L2.1875 10.3127L10.625 1.87519Z" stroke="#828282" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        }

                        {props.errorMessage &&
                            <Form.Control.Feedback type="invalid" className={`${styles.errorDiv} ${styles.errorMsg}`}>
                                {props.errorMessage}
                            </Form.Control.Feedback>
                        }

                    </Form.Group>
                    : props.isSavableAndCancelable === true ?
                        <Form.Group controlId={props.controlId} className={styles.formGroup}>
                            {props.label &&
                                <Form.Label
                                    className={`
                                    ${props.classLabel === 'defaultLabel' ? styles.defaultLabel : ''}
                                    ${props.classLabel === 'disabledLabel' ? styles.disabledLabel : ''}
                                `}>
                                    {props.label}
                                </Form.Label>
                            }
                            <Form.Control
                                className={`
                                ${props.classType === 'defaultTextBox' ? styles.defaultTextBox : ''}
                                ${props.classType === 'disabledTextBox' ? styles.disabledTextBox : ''}
                                ${props.classType === 'savableAndCancelableTextBox' ? styles.savableAndCancelableTextBox : ''}
                            `}
                                placeholder={props.placeholder}
                                onChange={props.handleOnChange}
                                name={props.name}
                                type={props.type}
                                isInvalid={props.isInvalid}
                                value={props.value}
                                disabled={props.disabled}
                                maxLength={props.maxlength}
                                pattern={props.pattern}
                                readOnly={props.readOnly}
                                key={props.keyValue}
                                required={props.required}
                            />

                            {props.isLoading ?
                                <div className={styles.loadingIcon}>
                                    <i className="fas fa-circle-notch fa-spin" aria-hidden="true"></i>
                                </div>
                            :props.isOthersLoading?
                                null
                            :
                                <div>
                                    <div className={styles.deleteIconDiv} onClick={props.onDeleteClick}>
                                        <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11.25 6.75L6.75 11.25" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.75 6.75L11.25 11.25" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div className={styles.saveIconDiv} onClick={props.onSaveClick}>
                                        <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25H12L15.75 6V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75Z" stroke="#2DA6C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.75 15.75V9.75H5.25V15.75" stroke="#2DA6C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M5.25 2.25V6H11.25" stroke="#2DA6C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            }

                            {props.errorMessage &&
                                <Form.Control.Feedback type="invalid" className={`${styles.errorDiv} ${styles.errorMsg}`}>
                                    {props.errorMessage}
                                </Form.Control.Feedback>
                            }

                        </Form.Group>
                        : props.isColorPicker === true ?
                            <Form.Group controlId={props.controlId} className={styles.formGroup}>
                                {props.label &&
                                <div className={styles.labelIcon}>
                                    <div>
                                        <Form.Label
                                            className={`
                                        ${props.classLabel === 'defaultLabel' ? styles.defaultLabel : ''}
                                        ${props.classLabel === 'disabledLabel' ? styles.disabledLabel : ''}
                                        `}>
                                            {props.label}
                                        </Form.Label>
                                        </div>
                                        <div className={styles.editIconDivs} data-for={props.toolTipId} data-tip>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                            <ReactTooltip id={props.toolTipId} type={props.toolTipType === "error" ? "error" : props.toolTipType === "dark" ? "dark" : "dark"}>
                                                <span>{props.toolTip}</span>
                                            </ReactTooltip>
                                        </div>
                                </div>
                                }
                                <div className={styles.inputContainer}>
                                    <input type="color" value={props.value ? props.value:'#bdbdbd'} disabled className={styles.colorCodePreview}></input>
                                    <Form.Control
                                        className={`
                                        ${props.classType === 'defaultTextBox' ? styles.defaultTextBoxColor : ''}
                                        ${props.classType === 'disabledTextBox' ? styles.disabledTextBox : ''}
                                        ${props.classType === 'savableAndCancelableTextBox' ? styles.savableAndCancelableTextBox : ''}
                                    `}
                                        placeholder={props.placeholder}
                                        onChange={props.handleOnChange}
                                        name={props.name}
                                        type={props.type}
                                        isInvalid={props.isInvalid}
                                        value={props.value}
                                        disabled={props.disabled}
                                        maxLength={props.maxlength}
                                        pattern={props.pattern}
                                        readOnly={props.readOnly}
                                        key={props.keyValue}
                                        required={props.required}
                                    />
                                    <div>
                                        <div className={styles.clearFieldIconDiv} onClick={props.onDeleteClick}>
                                            <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.25 6.75L6.75 11.25" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.75 6.75L11.25 11.25" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {props.errorMessage &&
                                    <Form.Control.Feedback type="invalid" className={`${styles.errorDiv} ${styles.errorMsg}`}>
                                        {props.errorMessage}
                                    </Form.Control.Feedback>
                                }

                            </Form.Group>
                        :props.isUrlPreview?
                                <Form.Group controlId={props.controlId}>
                                    {props.label &&
                                        <Form.Label
                                            className={`
                                            ${props.classLabel === 'defaultLabel' ? styles.defaultLabel : ''} 
                                            ${props.classLabel === 'disabledLabel' ? styles.disabledLabel : ''}
                                        `}>
                                            {props.label}&nbsp;&nbsp;&nbsp;<span className={styles.previewURL}>{props.urlPreview}</span>
                                        </Form.Label>
                                    }
                                    <Form.Control
                                        className={`
                                            ${props.classType === 'defaultTextBox' ? styles.defaultTextBox : ''}
                                            ${props.classType === 'disabledTextBox' ? styles.disabledTextBox : ''}
                                            ${props.classType === 'savableAndCancelableTextBox' ? styles.savableAndCancelableTextBox : ''}
                                        `}
                                        placeholder={props.placeholder}
                                        onChange={props.handleOnChange}
                                        name={props.name}
                                        type={props.type}
                                        isInvalid={props.isInvalid}
                                        value={props.value}
                                        disabled={props.disabled}
                                        maxLength={props.maxlength}
                                        pattern={props.pattern}
                                        readOnly={props.readOnly}
                                        onClick={props.onClick}
                                    />
                                    {props.errorMessage &&
                                        <Form.Control.Feedback type="invalid" className={styles.errorMsg}>
                                            {props.errorMessage}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                        :props.isTextBoxHoverToolTipEnable === true ?
                        <Form.Group controlId={props.controlId}>
                                    {props.label &&

                                    <div className={styles.labelIcon}>
                                        <div>
                                            <Form.Label
                                                className={`
                                                ${props.classLabel === 'defaultLabel' ? styles.defaultLabel : ''} 
                                                ${props.classLabel === 'disabledLabel' ? styles.disabledLabel : ''}
                                            `}>
                                                {props.label}&nbsp;&nbsp;&nbsp;<span className={styles.previewURL}>{props.urlPreview}</span>
                                            </Form.Label>
                                            </div>                                            
                                            <div className={styles.editIconDivs} data-for={props.toolTipIds} data-tip>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                                <ReactTooltip id={props.toolTipIds} type={props.toolTipType === "error" ? "error" : props.toolTipType === "dark" ? "dark" : "dark"}>
                                                    <span>{"This will be the URL format of vacancy pages"}</span>
                                                </ReactTooltip>
                                            </div>
                                    </div>
                                    }
                                <div data-tip data-for={props.toolTipId}>
                                    <Form.Control
                                        className={`
                                            ${props.classType === 'defaultTextBox' ? styles.defaultTextBox : ''}
                                            ${props.classType === 'disabledTextBox' ? styles.disabledTextBox : ''}
                                            ${props.classType === 'savableAndCancelableTextBox' ? styles.savableAndCancelableTextBox : ''}
                                        `}
                                        placeholder={props.placeholder}
                                        onChange={props.handleOnChange}
                                        name={props.name}
                                        type={props.type}
                                        isInvalid={props.isInvalid}
                                        value={props.value}
                                        disabled={props.disabled}
                                        maxLength={props.maxlength}
                                        pattern={props.pattern}
                                        readOnly={props.readOnly}
                                        onClick={props.onClick}
                                    />
                                </div>
                                    {props.disabled === true &&
                                            <ReactTooltip id={props.toolTipId} type={props.toolTipType === "error" ? "error" : props.toolTipType === "dark" ? "dark" : "dark"}>
                                                <span>{props.toolTip}</span>
                                            </ReactTooltip>
                                        
                                    }
                                    {props.errorMessage &&
                                        <Form.Control.Feedback type="invalid" className={styles.errorMsg}>
                                            {props.errorMessage}
                                        </Form.Control.Feedback>
                                    }
                                </Form.Group>
                        :
                        <Form.Group controlId={props.controlId}>
                            {props.label &&
                                <Form.Label
                                    className={`
                            ${props.classLabel === 'defaultLabel' ? styles.defaultLabel : ''} 
                            ${props.classLabel === 'disabledLabel' ? styles.disabledLabel : ''}
                            `}>     
                                {props.labelToolTipId && props.labelToolTip ?

                                    <p className={styles.labelWithToolTip}> {props.label } &nbsp;
                                          
                                             <svg data-tip data-for={props.labelToolTipId } xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                            <ReactTooltip id={props.labelToolTipId } place="right" type="dark" effect="solid" clickable={true}>
                                                                        <div className={styles.toolTipBox}>{<p>{props.labelToolTip }</p>}</div>
                                                                        </ReactTooltip>
                                    </p> 

                                    : props.label 
                                }
                                                                        
                                </Form.Label>
                            }
                            <Form.Control
                                className={`
                            ${props.classType === 'defaultTextBox' ? styles.defaultTextBox : ''}
                            ${props.classType === 'disabledTextBox' ? styles.disabledTextBox : ''}
                            ${props.classType === 'savableAndCancelableTextBox' ? styles.savableAndCancelableTextBox : ''}
                            `}
                                placeholder={props.placeholder}
                                onChange={props.handleOnChange}
                                name={props.name}
                                type={props.type}
                                isInvalid={props.isInvalid}
                                value={props.value}
                                disabled={props.disabled}
                                maxLength={props.maxlength}
                                pattern={props.pattern}
                                readOnly={props.readOnly}
                                onClick={props.onClick}
                            />
                            {props.errorMessage &&
                                <Form.Control.Feedback type="invalid" className={styles.errorMsg}>
                                    {props.errorMessage}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
            }
        </div>

    );
};

CustomTextField.defaultProps = {
    classType: 'defaultTextBox',
    classLabel: 'defaultLabel',
    isCancelable: false,
    isEditable: false,
    toolTipType:"dark"
};

