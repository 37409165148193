import React, { useState, useEffect } from 'react';
import { Row, Col, ProgressBar, Container, Image } from 'react-bootstrap';
import styles from './assessment.module.scss';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import CustomDropZone from '../shared/customDropZone';
import { CustomCheckBox } from '../shared/customCheckBox';
import { CustomButton } from '../shared/customButton'
import { Redirect, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getAssessmentDetails, getUploadedAssessmentURL, submitAssessment, uploadExternalAssessmentFile } from '../../services/util/externalAssessment';
import { CustomPopUpBox } from '../shared/customPopUpBox';
import { CustomConfirmationModal } from '../shared/customConfirmationModal';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';
import 'react-quill/dist/quill.snow.css';

export default function ExternalAssessment(props) {

    const history = useNavigate();

    let [isAcknowledgeConfirmed, setAcknowledgeConfirmed] = useState(false);
    let [isLoading, setLoading] = useState(false);
    let [assesmentDetails, setAssessmetDetails] = useState('');
    let [showSubmitPopup, setShowSubmitPopup] = useState(false);
    let [modalActionButtonLoading, setModalActionButtonLoading] = useState(false);
    let [SubmitData, setSubmitData] = useState();
    let [primaryColor, setPrimaryColor] = useState('');




    const changeAcknowledgeState = () => {
        setAcknowledgeConfirmed(!isAcknowledgeConfirmed);
    }

    let { id } = useParams();


    const loadExternalAssessmentDeatails = async () => {
        let value = await getAssessmentDetails(id);
        if (value.success == true) {
            if (value.data.is_assessment_already_submitted) {
                history("/submitted")
            }
            else if (value.data.is_assessment_expired) {
                history("/expired")
            } else {
                setAssessmetDetails(value);
                setPrimaryColor(value.data.primary_color ? value.data.primary_color : '#24859a')
            }

        }

    }

    const schema = yup.object({
        uploadedFile: yup.array().required('Required')
    });

    //on submit function
    const onSubmit = async (values) => {
        if (isAcknowledgeConfirmed) {
            // setLoading(true);
            setShowSubmitPopup(true);
            setSubmitData(values);
        }
    };


    const submitConfirmed = async () => {
        setModalActionButtonLoading(true);
        let FileType = (SubmitData.uploadedFile && ((SubmitData.uploadedFile).length > 0)) ? ((SubmitData.uploadedFile[0].type).split('/')[1]).toLowerCase() : null;

        let UploadedAssessmentURL = await getUploadedAssessmentURL(id, FileType);
        if (UploadedAssessmentURL.success == true) {
            let response = await uploadExternalAssessmentFile(UploadedAssessmentURL.data.presigned_url, SubmitData.uploadedFile[0]);
            if (response.status == 200) {
                let response = await submitAssessment(id, FileType);
                if (response.success) {
                    setModalActionButtonLoading(false);
                    history("/accepted")
                }
                else {
                    setModalActionButtonLoading(false);
                    history("/expired")
                }
            }

        }

    }

    const handleClose = () => {
        setShowSubmitPopup(false);
    }

    //set countdown 
    // Renderer callback with condition
    const setCountDownTime = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            loadExternalAssessmentDeatails();
        } else {
            // Render a countdown
            if (days) {
                return (
                    <span>
                        {days} days  {hours} hrs {minutes} min
                    </span>
                );
            } else if (hours) {
                return (
                    <span>
                        {hours} hrs {minutes} min
                    </span>
                );
            }
            else if (minutes) {
                return (
                    <span>
                        {minutes} min {seconds} sec
                    </span>
                );
            } else {
                return (
                    <span>
                        {seconds} sec
                    </span>
                );
            }

        }
    };

    useEffect(() => {
        loadExternalAssessmentDeatails();
    }, []);

    //set company selected color as the primary color
    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty(
            "--selected-primary-color", primaryColor
        );
    }, [primaryColor]);



    return (


        <div>
            {!assesmentDetails ?
                <div>
                    <div className={styles.overlay}></div>
                    <div className={`${styles.spanner} ${styles.show}`}>
                        <div className={styles.loader}></div>
                        <p className={styles.loaderText}>Please Wait...</p>
                    </div>
                </div>
                :
                <div>
                    <CustomConfirmationModal
                        show={showSubmitPopup}
                        cancelIconclick={handleClose}
                        modalCancelBtnDisabled={modalActionButtonLoading === true ? true : false}
                        onSecondaryBtnClick={handleClose}
                        onPrimaryBtnClick={submitConfirmed}
                        isSecondaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                        isPrimaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                        primaryBtnBackSideIcon={modalActionButtonLoading === true ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                        confirmationTopic="Are you sure?"
                        confirmationMessage={`Once you submit can't submit again.`}
                        secondaryBtnName="backBtn"
                        secondaryBtnType="button"
                        secondaryBtnCssType="defaultBtn"
                        secondaryBtnLabel="Cancel"
                        primaryBtnName="submitBtn"
                        primaryBtnType="submit"
                        primaryBtnCssType="successBtn"
                        primaryBtnLabel="Yes, Submit!"
                        confirmationType='success'
                    />
                    <div>

                        <div>
                            <Row className={styles.bodyRow}>
                                <Col xs={12} md={6} className={styles.submissionFormView}>
                                    <Container className={styles.mainContainer}>
                                        <Col lg={{ span: 10, offset: 1 }}>
                                            <Col className={styles.companySecondaryHeaderBox}>

                                            </Col>
                                            <Col>
                                                <p className={styles.pageTopic}>Assignment Details</p>
                                            </Col>
                                            <div className={styles.assessmentMainDiv}>
                                                <p className={styles.assessmentMain}>You have been invited to participate in a assignment. Please read the guidelines and do it and submit your answers.</p>
                                            </div>
                                            <div className='mt-3'>
                                                <p className={styles.assessmentDetailsMain}>Assignment :</p>
                                            </div>
                                            <div className={styles.assessmentContent}>

                                                <div className="ql-editor" style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: assesmentDetails.data.assessment_body }}></div>
                                                <Row className={styles.GuidelinesSecond}>
                                                    <Col lg={3} xs={3} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameSecondTag}>Submission Deadline Date </p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className='ml-3'>: {moment(assesmentDetails.data.assessment_expiration_date).format('MMMM Do YYYY, h:mm a')} </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            

                                            <div className='mt-5'>
                                                <Formik
                                                    validationSchema={schema}
                                                    onSubmit={(values) => onSubmit(values)}
                                                    initialValues={{ uploadedFile: '' }}>

                                                    {({
                                                        errors,
                                                        handleChange,
                                                        handleSubmit,
                                                        setFieldValue,
                                                        submitCount,
                                                        resetForm,
                                                        values
                                                    }) => (
                                                        <Form noValidate onSubmit={handleSubmit}>
                                                            <div className={styles.dragAndDropDiv}>
                                                                <CustomDropZone
                                                                    //selectedFile={props.formValues.uploadedFile && ((props.formValues.uploadedFile).length > 0) && props.formValues.uploadedFile[0].name}
                                                                    label="Upload answer/submission file"
                                                                    miniLabel="(Accept all file types)"
                                                                    classLabel="defaultLabel"
                                                                    isMultiUpload={false}
                                                                    isImageUpload={false}
                                                                    maxFileSize={104857600}
                                                                    viewBoxIcon={
                                                                        <svg width="62" height="51" viewBox="0 0 62 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M41.3332 34L30.9998 25.5L20.6665 34" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M31 25.5V44.625" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M52.6739 39.0789C55.1936 37.949 57.184 36.161 58.3311 33.9972C59.4782 31.8334 59.7167 29.417 59.0088 27.1294C58.301 24.8417 56.6871 22.8131 54.422 21.3637C52.1569 19.9143 49.3695 19.1267 46.4998 19.1251H43.2448C42.4628 16.6373 41.0054 14.3276 38.9821 12.3698C36.9588 10.4119 34.4223 8.85683 31.5632 7.82144C28.7041 6.78605 25.5969 6.29729 22.4751 6.3919C19.3533 6.48651 16.2982 7.16204 13.5395 8.36769C10.7808 9.57334 8.39021 11.2777 6.54755 13.3528C4.70488 15.4278 3.45806 17.8194 2.90081 20.3479C2.34356 22.8763 2.49038 25.4758 3.33024 27.9508C4.17011 30.4259 5.68115 32.7121 7.74978 34.6376" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M41.3332 34L30.9998 25.5L20.6665 34" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                    }
                                                                    uploadFileTopic={'Drag and Drop or Click to select file'}
                                                                    maximumFileSizeInMBLabel={'100'}
                                                                    setFieldValue={setFieldValue}
                                                                    name='uploadedFile'
                                                                    errorMessage={submitCount > 0 && errors.uploadedFile}
                                                                    isInvalid={(submitCount > 0 && errors.uploadedFile) ? true : false}
                                                                />
                                                            </div>

                                                            <div className="mt-4">

                                                                <div className="mt-3">
                                                                    <CustomCheckBox
                                                                        id="acceptConditions"
                                                                        controlId="acceptConditions"
                                                                        name="condition"
                                                                        label="I hereby confirm that I understand that the uploaded file cannot be changed later."
                                                                        handleOnChange={() => changeAcknowledgeState()}
                                                                        type="defaultCheckBox"
                                                                        ischecked={isAcknowledgeConfirmed}
                                                                    />
                                                                </div>
                                                                <Col lg={{ span: 8, offset: 2 }} className={styles.submitButton}>

                                                                    <CustomButton
                                                                        buttonType="submit"
                                                                        label="Mark as Completed"
                                                                        type="primaryBtn"
                                                                        disabled={
                                                                            (isLoading === true || isAcknowledgeConfirmed === false) ? true : false
                                                                        }
                                                                        backicon={isLoading === true && <i className="fas fa-circle-notch fa-spin"></i>}
                                                                    //handleClick={() => submitUserData()}
                                                                    />

                                                                </Col>

                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>

                                            <div className={styles.submitTextCol}>
                                                <p className={styles.signupBottomMessage}>By clicking the &quot;Mark as Completed&quot; button, you&lsquo;re agreeing to accept the HireTrace </p>
                                                <p className={styles.termsPara}><a href="https://hiretrace.io/privacy-policy" className={styles.termsLink} target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://hiretrace.io/terms-and-conditions" className={styles.termsLink} target="_blank" rel="noreferrer">Terms of Use.</a></p>
                                            </div>


                                        </Col>
                                    </Container>
                                </Col>

                                <Col xs={12} md={6} className={styles.descriptionBox}>
                                    <Col xs={12} className={styles.descriptionInnerBox}>
                                        <Col className={styles.companyPrimaryHeaderBox}>
                                            <Col className="text-end mb-3">
                                                <div className={styles.timeleft}> <Countdown date={Date.now() + assesmentDetails.data.remaining_days_before_expiration} renderer={setCountDownTime} /> left</div>
                                            </Col>
                                            <Col className="text-center mt-5">
                                                {assesmentDetails.data.company_logo_url ?
                                                    <Col xs={{ span: 10, offset: 1 }}>
                                                        <Image src={assesmentDetails.data.company_logo_url} className={styles.companyLogo} alt={assesmentDetails.data.company_name + " logo"} />
                                                    </Col>
                                                    :
                                                    <p className={styles.companyName}>{assesmentDetails.data.company_name}</p>
                                                }
                                            </Col>
                                            <Col className="mt-3">
                                                <Row className={styles.GuidelinesRow}>
                                                    <Col lg={2} md={5} xs={5} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Candidate Name</p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue}>: {assesmentDetails.data.candidate_name} </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className={styles.GuidelinesRow}>
                                                    <Col lg={2} md={5}  xs={5} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Position</p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue}>: {assesmentDetails.data.hiring_position} </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className={styles.GuidelinesRow}>
                                                    <p className={styles.nameTagValue}>You are required to complete this assignment as a requirement of the evaluation process of the above vacancy. </p>
                                                </Row>
                                            </Col>
                                        </Col>

                                        <Col className="mt-2">

                                            <Container className={styles.subTopicMainBody}>
                                                <div className={styles.guidlineTopicDiv}>
                                                    <div className={styles.pageGuidlineTopic}><p>Assignment Guideline</p></div>
                                                </div>
                                                <div className={styles.guidlinecontentMaindiv}>
                                                    <div className={styles.guidelinecontentCol}>1.&nbsp;&nbsp;</div>
                                                    <div className={styles.guidelinecontentCol}>Read the assignment details (check left side) carefully.</div>
                                                </div>
                                                <div className={styles.guidlinecontentdiv}>
                                                    <div className={styles.guidelinecontentCol}>2.&nbsp;</div>
                                                    <div className={styles.guidelinecontentCol}> Complete the assignment as instructed.</div>
                                                </div>
                                                <div className={styles.guidlinecontentdiv}>
                                                    <div className={styles.guidelinecontentCol}>3.&nbsp;</div>
                                                    <div className={styles.guidelinecontentCol}>Upload assignment answers or proof of completion using the "Upload File" option (check left side).
                                                        (You can upload only a
                                                        single file. If you have multiple files, create a single ZIP file and upload).</div>
                                                </div>
                                                <div className={styles.guidlinecontentdiv}>
                                                    <div className={styles.guidelinecontentCol}>4.&nbsp;</div>
                                                    <div className={styles.guidelinecontentCol}>Finally mark the submission as completed (Click on "Mark As Completed").</div>
                                                </div>



                                                <div className={styles.guidlineTopicDiv}>

                                                    <div className={styles.pageGuidlineTopic}>
                                                        <p>Important</p>
                                                    </div>

                                                </div>
                                                <Row className='mt-4 ml-1'>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.Guidelines}>
                                                            <p className={styles.guidelinecontentCol}> Make sure to complete the assignment and click on “ Mark as Completed” before the remaining time overdue.</p>

                                                        </div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.Guidelines}>
                                                            <p className={styles.guidelinecontentCol}>If you have any questions related to "Assignment Details", please reach to <a href={`mailto:${assesmentDetails.data.contact_email}`} className={styles.guidelineLinks}>{assesmentDetails.data.contact_email}</a>.</p>
                                                        </div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.Guidelines}>
                                                            <p className={styles.guidelinecontentCol}>If you face any issues during "Uploading" or "Mark as Complated", please reach to <a href={`mailto:support@hiretrace.io`} className={styles.guidelineLinks}>support@hiretrace.io</a>.</p>
                                                        </div>
                                                    </div>

                                                </Row>
                                            </Container>
                                        </Col>

                                        <Row className='text-center mt-5'>
                                            <Col className={styles.footerCreditBox}>
                                                <p>Powered By <span> <a className={styles.footerCreditLink} href="https://hiretrace.io" rel="noreferrer" target="_blank"> HireTrace</a></span></p>
                                            </Col>
                                        </Row>

                                    </Col>


                                </Col>
                            </Row>
                        </div>

                    </div>

                </div>
            }
        </div >

    )
}