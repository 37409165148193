import React from 'react';
import { Form } from 'react-bootstrap';
import styles from './customCheckBox.module.scss';

export const CustomCheckBox = (props) => {
    return (
        <div className='mt-2'>
            <Form.Group className={styles.formText} controlId={props.controlId}>
                <Form.Check
                    className={`
                    ${props.type === 'defaultCheckBox'
                            ? styles.defaultCheckBox
                            : ''
                        }
                    ${props.type === 'defaultCheckBoxLight' ? styles.defaultCheckBoxLight : ''}
                    
                  `}
                    type="checkbox">
                    <Form.Check.Input
                        onChange={props.handleOnChange}
                        type="checkbox"
                        isInvalid={props.isInvalid}
                        checked={props.ischecked}
                        value={props.value}
                    />
                    <Form.Check.Label>
                        <div className={styles.labelCheckBox}>
                            <p className={
                                props.isInvalid
                                    ? styles.formCheckLabelInvalid
                                    : styles.formCheckLabel
                            }> {props.label}&nbsp;&nbsp;
                        {props.link && (
                                    <a href={props.link} target="_blank" rel="noreferrer">
                                        <span
                                            className={
                                                props.isInvalid
                                                    ? styles.checkBoxLinkTextInvalid
                                                    : styles.checkBoxLinkText
                                            }>
                                            {props.linkText}
                                        </span>
                                    </a>
                                )}
                            
                            {props.secondarylink && (
                                    <a href={props.secondarylink} target="_blank" rel="noreferrer">
                                        <span
                                            className={
                                                props.isInvalid
                                                    ? styles.checkBoxLinkTextInvalid
                                                    : styles.checkBoxLinkText
                                            }>
                                            {props.secondarylinkText}
                                        </span>
                                    </a>
                                )}
                            </p>
                        </div>

                    </Form.Check.Label>
                </Form.Check>
            </Form.Group>
        </div>
    )
}
