import React from 'react';
import Routes from './routes/Routes.js';

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}
export default App;
