import React from 'react'
import styles from './response.module.scss'


export default function OfferSubmittedResponse() {

    return (

        <div >
            <div className={styles.container}>
                <p className={styles.content}>
                    You have submitted your response to the job offer successfully !!
                </p>

            </div>

        </div>


    )
}