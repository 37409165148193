import React, { useState } from 'react';
import styles from './customDropZone.module.scss';
import { Form, Row, Col, Image } from 'react-bootstrap';
import Dropzone from 'react-dropzone'
import Resizer from "react-image-file-resizer";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CustomDropZone(props) {

    const [cvObject, setCVObject] = useState(null)

    const pdfPreviewOptions = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
    };

    const setPdfFile = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            let fileURL = URL.createObjectURL(acceptedFiles[0]);
            setCVObject({ url: fileURL })
            props.setFieldValue(props.name, acceptedFiles);
        }
    }

    const onPDFError = () => {
        props.setFieldValue(props.name, []);
    }

    const failedPDFErrorMsg = () => {
        return (
            <p className={styles.errorLabel}>PDF file is damaged or invalid.</p>
        )
    }


    const setImageFile = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            resizeCandidateImage(acceptedFiles[0]);
        }
    }

    // optimize candidate image resolution 
    const resizeCandidateImage = async (original_file) => {

        let file_extention = (original_file.type === 'image/png') ? 'PNG' : 'JPEG';
        let resized_image = await resizeFile(original_file, file_extention);

        props.setFieldValue(props.name, [resized_image]);
    }

    //actual optimizer method
    const resizeFile = (file, file_extention) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                200,
                200,
                file_extention,
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });




    return (
        <div>
            <Form.Group controlId={props.controlId} className={styles.formGroup}>
                {props.label &&
                    <Form.Label
                        className={
                            `${props.classLabel === 'defaultLabel' ? styles.defaultLabel : ''}
                                ${props.classLabel === 'disabledLabel' ? styles.disabledLabel : ''}
                            `}>
                        {props.label}&nbsp;&nbsp;&nbsp; <span>{props.miniLabel}</span>
                    </Form.Label>
                }
                {props.isImageUpload ?
                    (
                        <Dropzone multiple={props.isMultiUpload} maxFiles={props.maxFilesNumber} maxSize={props.maxFileSize} accept={props.acceptFileTypes} onDrop={acceptedFiles => setImageFile(acceptedFiles)} >
                            {({ getRootProps, getInputProps, fileRejections, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps({ className: props.isInvalid === true ? styles.dropzoneInvalid : styles.dropzone })}>
                                        <input {...getInputProps()} />
                                        {acceptedFiles.length > 0 ?

                                            acceptedFiles && acceptedFiles.map((file, idx) => (
                                                <Col key={idx}>
                                                    <div className={styles.image_container}>
                                                        <Image src={URL.createObjectURL(file)} fluid alt="candidate image preview" />
                                                    </div>
                                                </Col>
                                            ))
                                            :
                                            <div className="text-center">
                                                {props.viewBoxIcon}
                                                <p className={styles.filetopic}><b>{props.uploadFileTopic}</b></p>
                                                <em>{props.onlyAcceptedFileTypesLabel && (props.onlyAcceptedFileTypesLabel)}</em>
                                            </div>
                                        }
                                    </div>
                                    <span className={styles.fileSizeLabel}>Maximum upload file size: <b>{props.maximumFileSizeInMBLabel}MB</b></span>
                                    <aside>
                                        {
                                            fileRejections.map(({ file, errors }) => (
                                                <div key={file.path}>
                                                    {errors.map((e, idx) => (
                                                        <p key={idx} className={styles.errorLabel}>{e.code === 'file-too-large' ? `File is larger than ${props.maximumFileSizeInMBLabel}MB` : e.code === 'file-invalid-type' ? 'Invalid file type' : e.code === 'too-many-files' ? 'Maximum 10 files allowed' : e.message}</p>
                                                    ))}
                                                </div>
                                            ))
                                        }
                                        {
                                            acceptedFiles && acceptedFiles.map((file, idx) => (
                                                <p key={idx} className={styles.fileNameLabel}>{file.path}</p>
                                            ))
                                        }
                                        {props.selectedFile && !acceptedFiles.length > 0 && !fileRejections.length > 0 &&
                                            <p className={styles.fileNameLabelSuccess}>{props.selectedFile}</p>
                                        }
                                        {props.errorMessage && <p className={styles.errorLabel}>{props.errorMessage}</p>}
                                    </aside>
                                </section>
                            )}
                        </Dropzone>
                    )
                    :
                    props.isPDFUpload ?
                    (
                        <Dropzone multiple={props.isMultiUpload} maxFiles={props.maxFilesNumber} maxSize={props.maxFileSize} accept={props.acceptFileTypes} onDrop={acceptedFiles => setPdfFile(acceptedFiles)} >
                            {({ getRootProps, getInputProps, fileRejections, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps({ className: props.isInvalid === true ? styles.dropzoneInvalid : styles.dropzone })}>
                                        <input {...getInputProps()} />

                                        {acceptedFiles.length > 0 ?

                                            acceptedFiles && acceptedFiles.map((file, idx) => (
                                                <Col key={idx}>
                                                    <div className={styles.image_container}>
                                                        <Document
                                                            file={cvObject}
                                                            options={pdfPreviewOptions}
                                                            onLoadError={onPDFError}
                                                            onSourceError={onPDFError}
                                                            error={failedPDFErrorMsg}
                                                        >
                                                            <Page pageNumber={1} scale={0.24} />
                                                        </Document>
                                                    </div>
                                                </Col>
                                            ))
                                            :
                                            <div className="text-center">
                                                {props.viewBoxIcon}
                                                <p className={styles.filetopic}><b>{props.uploadFileTopic}</b></p>
                                                <em>{props.onlyAcceptedFileTypesLabel && (props.onlyAcceptedFileTypesLabel)}</em>
                                            </div>
                                        }
                                    </div>
                                    <span className={styles.fileSizeLabel}>Maximum upload file size: <b>{props.maximumFileSizeInMBLabel}MB</b></span>
                                    <aside>
                                        {
                                            acceptedFiles && acceptedFiles.map((file, idx) => (
                                                <p key={idx} className={styles.fileNameLabel}>{file.path}</p>
                                            ))
                                        }
                                        {props.selectedFile && !acceptedFiles.length > 0 && !fileRejections.length > 0 &&
                                            <p className={styles.fileNameLabelSuccess}>{props.selectedFile}</p>
                                        }
                                        {
                                            fileRejections.map(({ file, errors }) => (
                                                <div key={file.path}>
                                                    {errors.map((e, idx) => (
                                                        <p key={idx} className={styles.errorLabel}>{e.code === 'file-too-large' ? `Uploaded file is larger than ${props.maximumFileSizeInMBLabel}MB` : e.code === 'file-invalid-type' ? 'Invalid file type' : e.code === 'too-many-files' ? 'Maximum 10 files allowed' : e.message}</p>
                                                    ))}
                                                </div>
                                            ))
                                        }
                                        {props.errorMessage && <p className={styles.errorLabel}>{props.errorMessage}</p>}
                                    </aside>
                                </section>
                            )}
                        </Dropzone>
                    ) :
                    
                    (
                        <Dropzone multiple={props.isMultiUpload} maxFiles={props.maxFilesNumber} maxSize={props.maxFileSize} accept={props.acceptFileTypes} onDrop={acceptedFiles => setPdfFile(acceptedFiles)} >
                            {({ getRootProps, getInputProps, fileRejections, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps({ className: props.isInvalid === true ? styles.dropzoneInvalid : styles.dropzone })}>
                                        <input {...getInputProps()} />

                                        <div className="text-center">
                                                {props.viewBoxIcon}
                                                <p className={styles.filetopic}><b>{props.uploadFileTopic}</b></p>
                                                <em>{props.onlyAcceptedFileTypesLabel && (props.onlyAcceptedFileTypesLabel)}</em>
                                            </div>
                                    </div>
                                    <span className={styles.fileSizeLabel}>Maximum upload file size: <b>{props.maximumFileSizeInMBLabel}MB</b></span>
                                    <aside>
                                        {
                                            acceptedFiles && acceptedFiles.map((file, idx) => (
                                                <p key={idx} className={styles.fileNameLabel}>{file.path}</p>
                                            ))
                                        }
                                        {props.selectedFile && !acceptedFiles.length > 0 && !fileRejections.length > 0 &&
                                            <p className={styles.fileNameLabelSuccess}>{props.selectedFile}</p>
                                        }
                                        {
                                            fileRejections.map(({ file, errors }) => (
                                                <div key={file.path}>
                                                    {errors.map((e, idx) => (
                                                        <p key={idx} className={styles.errorLabel}>{e.code === 'file-too-large' ? `Uploaded file is larger than ${props.maximumFileSizeInMBLabel}MB` : e.code === 'file-invalid-type' ? 'Invalid file type' : e.code === 'too-many-files' ? 'Maximum 10 files allowed' : e.message}</p>
                                                    ))}
                                                </div>
                                            ))
                                        }
                                        {props.errorMessage && <p className={styles.errorLabel}>{props.errorMessage}</p>}
                                    </aside>
                                </section>
                            )}
                        </Dropzone>
                    )
                }
            </Form.Group>
        </div>
    )
}
