import React from 'react';
import styles from './response.module.scss'


export default function OfferRetracted(props) {

    return (

        <div >
            <div className={styles.container}>
                <p className={styles.contentExpired}>
                    The Company has retracted this job offer.
                </p>

            </div>

        </div>


    )
}