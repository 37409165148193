export const candidateInterviewRejectionReasonsArray = [
    {
        label: 'I am not available at this date and time',
        value: 0,
    },
    {
        label: 'I have already found a job',
        value: 1,
    },
    {
        label: 'I am not interested with this job vacancy',
        value: 2,
    },
    {
        label: 'Other',
        value: 3,
    },
]