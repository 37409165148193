import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import PrivateRoutes from './PrivateRoutes.js';
import PublicRoutes from './PublicRoutes.js';
//import signIn from '../components/signIn';
//import signUp from '../components/signUp';
//import AdminPanel from '../components/adminPanel';
//import ForgotPassword from '../components/forgotPassword';
//import EmailVerify from '../components/emailVerify';
//import NewUserRegister from '../components/newUserRegister';
//import ChangePassword from '../components/changePassword';
//import MySettings from '../components/mySettings';
import { createBrowserHistory } from 'history';
import NotFound from '../components/notFound';
import ExternalAssessment from '../components/assessments/index.js';
import AssessmentAccepted from '../components/assessments/review/accepted.js';
import AssessmentExpired from '../components/assessments/review/expired.js';
import AssessmentSubmitted from '../components/assessments/review/submitted.js';
import Quiz from '../components/quiz/index.js';
import Interview from '../components/interview/index.js';
import JobOffer from '../components/jobOffer';
import OfferAlreadySubmitted from '../components/jobOffer/review/alreadySubmittedResponse.js';
import OfferExpired from '../components/jobOffer/review/expired.js';
import OfferRetracted from '../components/jobOffer/review/offerRetracted.js';
import OfferSubmittedConfirmation from '../components/jobOffer/review/submittedConfirmation.js';
import OneWayInterview from '../components/oneWayInterview/index.js';
import ReferenceCheck from '../components/referenceCheck/index.js';
import ReferenceCheckSubmitted from '../components/referenceCheck/review/submitted.js';
import ReferenceExpired from '../components/referenceCheck/review/expired.js';
import ReferenceAccepted from '../components/referenceCheck/review/accepted.js';
export const history = createBrowserHistory();


const DirectRoutes = () => {

    return (
        <div>
            <BrowserRouter basename={'app'}>
                <Routes>
                    {/* Company Public Routes */}
                    {/* <PublicRoutes component={signIn} path={`/signin`} exact />
                    <PublicRoutes component={signUp} path={`/signup`} exact />
                    <PublicRoutes component={ForgotPassword} path={`/forgotpassword`} exact />
                    <PublicRoutes component={NewUserRegister} path={`/join-company-user/:id`} exact /> */}

                    {/* Company Internal Routes */}
                    {/* <PrivateRoutes component={AdminPanel} path={`/company`} />
                    <PrivateRoutes component={EmailVerify} path={`/email-verify`} exact />
                    <PrivateRoutes component={ChangePassword} path={`/changePassword`} exact />
                    <PrivateRoutes component={MySettings} path={`/my-settings`} /> */}

                    {/* Candidate Routes */}
                    {/* <PublicRoutes component={Interview} path={`/candidate/interview/:id`} exact /> */}
                    {/* <PublicRoutes component={InterviewRSVP} path={`/candidate/interview-details/:id`} exact /> */}

                    <Route element={<PublicRoutes><ExternalAssessment /></PublicRoutes>} path="/assignment/:id" />
                    <Route element={<PublicRoutes><AssessmentExpired /></PublicRoutes>} path="/expired" />
                    <Route element={<PublicRoutes><AssessmentAccepted /></PublicRoutes>} path="/accepted" />
                    <Route element={<PublicRoutes><AssessmentSubmitted /></PublicRoutes>} path="/submitted" />

                    <Route element={<PublicRoutes><Quiz /></PublicRoutes>} path="/quiz/:id" />
                    <Route element={<PublicRoutes><Interview /></PublicRoutes>} path="/candidate/interview/:id" />
                    <Route element={<PublicRoutes><OneWayInterview /></PublicRoutes>} path="/candidate/one-way-interview/:id" />

                    {/* offer */}
                    <Route element={<PublicRoutes><JobOffer /></PublicRoutes>} path="/job-offer/:id" />
                    <Route element={<PublicRoutes><OfferAlreadySubmitted /></PublicRoutes>} path="/job-offer-submitted" />
                    <Route element={<PublicRoutes><OfferExpired /></PublicRoutes>} path="/job-offer-expired" />
                    <Route element={<PublicRoutes><OfferRetracted /></PublicRoutes>} path="/job-offer-retracted" />
                    <Route element={<PublicRoutes><OfferSubmittedConfirmation /></PublicRoutes>} path="/job-offer-accepted" />

                    {/* reference */}
                    <Route element={<PublicRoutes><ReferenceCheck /></PublicRoutes>} path="/reference/:id" />
                    <Route element={<PublicRoutes><ReferenceCheckSubmitted /></PublicRoutes>} path="/reference-submitted" />
                    <Route element={<PublicRoutes><ReferenceExpired /></PublicRoutes>} path="/reference-expired" />
                    <Route element={<PublicRoutes><ReferenceAccepted /></PublicRoutes>} path="/reference-accepted" />


                    {/* 404 error page */}
                    <Route path="*" element={<NotFound />} />


                </Routes>
            </BrowserRouter>
        </div>
    )
}
export default DirectRoutes;