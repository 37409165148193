import React, { useState, useEffect, useRef } from 'react';

import { usePageVisibility } from 'react-page-visibility';
import PageVisibility from 'react-page-visibility';
import styles from './quizBody.module.scss';
import { Row, Col, Form } from 'react-bootstrap';
import { CustomAlertBox } from '../../shared/customAlertBox';
import { Formik, FieldArray } from 'formik';
import Countdown from 'react-countdown';
import { CustomCheckBox } from '../../shared/customCheckBox';
import { CustomButton } from '../../shared/customButton'
import { startQuiz, submitQuiz, updateWarning, submitQuizTimeOver } from '../../../services/util/quiz';
import CSSLoader from '../../shared/cssLoader/index';
import { CustomPopUpBox } from '../../shared/customPopUpBox';
import { CustomMessagePopUpBox } from '../../shared/customMessagePopUpBox';
import { CustomConfirmationModal } from '../../shared/customConfirmationModal';
import { CustomConfirmationWithCheckPopUpBox } from '../../shared/customConfirmationWithCheckPopUpBox';
import { useParams, useNavigate } from 'react-router-dom';
const moment = require('moment');


const QuizBody = (props) => {

    const history = useNavigate();

    let [questionSessionId] = useState(props.id);

    let [quizDetails, setQuizDetails] = useState(null);
    let [questionDetails, setQuestionDetails] = useState(null);

    let [modalActionButtonLoading, setModalActionButtonLoading] = useState(false);
    let [skipQuestionButtonLoading, setSkipQuestionButtonLoading] = useState(false);
    let [submitAnswerButtonLoading, setSubmitAnswerButtonLoading] = useState(false);


    let [showSkipQuestionConfirmationPopup, setShowSkipQuestionConfirmationPopup] = useState(false);
    let [showQuizCompletedMessagePopup, setShowQuizCompletedMessagePopup] = useState(false);
    let [showNotFocusPopup, setShowNotFocusPopup] = useState(false);

    let [showEmptyAnswersError, setShowEmptyAnswersError] = useState(false);

    let [isPageloading, setIsPageLoading] = useState(true);
    let [isQuestionloading, setIsQuestionLoading] = useState(true);

    let [isWarningCountExceeded, setIsWarningCountExceeded] = useState(false);
    let [isTimeOver, setIsTimeOver] = useState(false);
    let [isVisibilityChangeTriggered, setIsVisibilityChangeTriggered] = useState(false);


    let quiz_session_id = props.id;

    const isVisible = usePageVisibility();
    const countDownRef = useRef();




    const startQuizWithFirstQuestion = async () => {

        setIsPageLoading(true);

        // get quiz details
        let respond = await startQuiz(questionSessionId);

        // setModalActionButtonLoading(true);
        if (respond.success == true) {
            handleQuizData(respond.data)
            handleQuestionData(respond.data);
        }
        else {
            window.location.reload();
        }
    }

    const handleQuizData = async (response_data) => {

        let quiz_obj = {
            company_name: response_data.company_name,
            quiz_title: response_data.quiz_title,
            remaining_time: response_data.remaining_time,
            expiration_time: Date.now() + response_data.remaining_time,
            total_question_count: response_data.total_question_count
        }

        setQuizDetails(quiz_obj);
        setIsPageLoading(false);
    }
    const handleQuestionData = async (response_data) => {

        if (response_data.is_quiz_attempts_exeeded || response_data.is_warnings_exeeded || response_data.is_quiz_finished) {
            window.location.reload();
        }
        else {

            let question_obj = {
                question_id: response_data.next_question.question_id,
                question: response_data.next_question.question,
                is_multiple_answer_question: response_data.next_question.is_multiple_answer_question,
                question_name: response_data.next_question.question_name,
                question_number: response_data.next_question.question_number,
                answers: [],
                is_the_last_question: response_data.is_the_last_question,
                is_quiz_finished: response_data.is_quiz_finished,
                is_quiz_attempts_exeeded: response_data.is_quiz_attempts_exeeded,
                question_started_time: moment().toDate().getTime() //record the currect time (to calculate spent time when submitting)
            }

            for (let i = 0; i < response_data.next_question.answers.length; i++) {

                let answer = {
                    answer_id: response_data.next_question.answers[i].answer_id,
                    answer: response_data.next_question.answers[i].answer,
                    is_correct: false
                }
                question_obj.answers.push(answer)
            }

            setQuestionDetails(question_obj)
            setIsQuestionLoading(false);
            handleCountDownStart();
        }
    }

    //candidate click on "Next" or "Submit & Finish" buttons
    const submitAnswers = async (values) => {

        setSubmitAnswerButtonLoading(true);
        let selected_answers = []

        for (let i = 0; i < values.answers.length; i++) {
            if (values.answers[i].is_correct) {
                selected_answers.push(values.answers[i].answer_id)
            }
        }

        if (selected_answers.length < 1) {
            setShowEmptyAnswersError(true)
            setSubmitAnswerButtonLoading(false);
        }
        else {
            let spent_time = moment().toDate().getTime() - questionDetails.question_started_time;
            handleCountDownPause();

            setIsQuestionLoading(true);
            let respond = await submitQuiz(questionSessionId, questionDetails?.question_id, selected_answers, spent_time);
            setSubmitAnswerButtonLoading(false);

            if (respond.success == true) {

                if (!respond.data.is_quiz_finished) {
                    handleQuestionData(respond.data)
                }
                else {
                    setShowQuizCompletedMessagePopup(true);
                }

            } else {
                //if error, reload to initial page
                window.location.reload();
            }
        }

    }


    //if candidate click on "Skip" question button
    const handleSkipQuestion = () => {
        setShowSkipQuestionConfirmationPopup(true);
    }

    //candidate confirmed skipping the question
    const submitSkippedQuestion = async () => {
        setModalActionButtonLoading(true);

        let spent_time = moment().toDate().getTime() - questionDetails.question_started_time;
        handleCountDownPause(); //stop count down until data returns

        setIsQuestionLoading(true);

        //submit with a empty answer array
        let respond = await submitQuiz(questionSessionId, questionDetails?.question_id, [], spent_time);

        if (respond.success == true) {

            setShowSkipQuestionConfirmationPopup(false);
            setModalActionButtonLoading(false);
            setSkipQuestionButtonLoading(false);

            if (!respond.data.is_quiz_finished) {
                handleQuestionData(respond.data);
            }
            else {
                setShowQuizCompletedMessagePopup(true);
            }

        } else {
            //if error, reload to initial page
            window.location.reload();
        }
    }

    // if candididate acknoledged to quiz completion
    const acknowledgeQuizCompletion = async () => {
        window.location.reload();
    }

    //reset error msg
    const resetNoAnswerError = async () => {
        setShowEmptyAnswersError(false);
        setSubmitAnswerButtonLoading(false);
    }



    // close all popup modals 
    const handleClose = () => {
        setShowSkipQuestionConfirmationPopup(false)
        setShowNotFocusPopup(false)
        setShowQuizCompletedMessagePopup(false);
        setModalActionButtonLoading(false)
    }


    //handle single answer radio button change. need to update answer array manually
    const handleRadioButtonChange = async (answers, selected_index) => {

        for (var i = 0; i < answers.length; i++) {
            if (i == selected_index) {
                answers[i].is_correct = true;

            }
            else {
                answers[i].is_correct = false;
            }
        }
    }


    const handleVisibilityChange = async (isVisible) => {

        if (!isVisible && !isVisibilityChangeTriggered) {
            setIsVisibilityChangeTriggered(true);
            let respond = await updateWarning(quiz_session_id);

            if (respond.success == true) {
                if (!respond.data.is_warnings_exeeded) {
                    setIsWarningCountExceeded(false);
                } else {
                    setIsWarningCountExceeded(true);
                }
                setShowNotFocusPopup(true);
            }
            else {
                window.location.reload();
            }
        }
    }

    const handleWarningAcknowledgement = async () => {

        if (isWarningCountExceeded) {
            window.location.reload();
        } else {
            setShowNotFocusPopup(false);
            setIsVisibilityChangeTriggered(false);
        }

    }

    const handleTimeOver = async () => {
        setIsTimeOver(true);
        let respond = await submitQuizTimeOver(quiz_session_id);

        if (respond.success == true) {
            setShowQuizCompletedMessagePopup(true);
        }
        else {
            window.location.reload();
        }

    }


    const handleCountDownStart = () => countDownRef.current.start();
    const handleCountDownPause = () => countDownRef.current.pause();

    const setCountDownTime = ({ days, hours, minutes, seconds, completed, api }) => {
        if (completed) {
            // Render  complete state
            return (
                <span className={api.isPaused() ? styles.countDownPaused : styles.countDownHighWarning}>
                    0 s
                </span>
            );
        } else {
            // Render a countdown
            if (days) {
                return (
                    <span className={api.isPaused() ? styles.countDownPaused : styles.countDownRunning}>
                        {days} days  {hours} hrs
                    </span>
                );
            } else if (hours) {
                return (
                    <span className={api.isPaused() ? styles.countDownPaused : styles.countDownRunning}>
                        {hours} hrs {minutes} mins
                    </span>
                );
            }
            else if (minutes) {

                if (minutes > 2) {
                    return (
                        <span className={api.isPaused() ? styles.countDownPaused : styles.countDownRunning}>
                            {minutes} mins {seconds} s
                        </span>
                    );
                }
                else {
                    return (
                        <span className={api.isPaused() ? styles.countDownPaused : styles.countDownWarning}>
                            {minutes} mins {seconds} s
                        </span>
                    );
                }

            } else {
                return (
                    <span className={api.isPaused() ? styles.countDownPaused : styles.countDownHighWarning}>
                        {seconds} s
                    </span>
                );
            }

        }
    };

    const onCountDownCompleted = () => {
        handleTimeOver();
    }


    useEffect(() => {
        startQuizWithFirstQuestion();
    }, [questionSessionId])

    return (
        <div>
            <CustomMessagePopUpBox
                show={showQuizCompletedMessagePopup}
                cancelIconclick={acknowledgeQuizCompletion}
                onPrimaryBtnClick={acknowledgeQuizCompletion}
                isSecondaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                isPrimaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                primaryBtnBackSideIcon={modalActionButtonLoading === true ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                topic="Done"
                message={`Thank you for completing the quiz on time. Your answers will be submitted to the company for evaluation.`}
                primaryBtnName="submitBtn"
                primaryBtnType="button"
                primaryBtnCssType="successBtn"
                primaryBtnLabel="OK!"
                confirmationType='success'
            />

            <PageVisibility onChange={handleVisibilityChange}>

                <CustomConfirmationWithCheckPopUpBox
                    show={showNotFocusPopup && !showQuizCompletedMessagePopup && !showSkipQuestionConfirmationPopup}
                    onFormSubmit={handleWarningAcknowledgement}
                    isPrimaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                    primaryBtnBackSideIcon={modalActionButtonLoading === true ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                    topic={!isWarningCountExceeded ? "You are not focused on this quiz.  " : "Warning Counts Exceeded "}
                    message={!isWarningCountExceeded ? `You are not allowed to go outside this browser tab. If you continued to do so, you will become ineligible for this quiz.` : 'Since you were not focused on the quiz, now you are not eligible to continue answering.'}
                    primaryBtnName="submitBtn"
                    primaryBtnType="submit"
                    primaryBtnCssType="dangerBtn"
                    primaryBtnLabel="Yes, I Understand!"
                    checkboxLabel="I hereby confirm that I understand that."
                    reasonLabel="true"
                    confirmationType='warning'
                    singleButtonOnly={true}
                />
            </PageVisibility>

            <CustomConfirmationModal
                show={showSkipQuestionConfirmationPopup}
                cancelIconclick={handleClose}
                onSecondaryBtnClick={handleClose}
                onPrimaryBtnClick={submitSkippedQuestion}
                isSecondaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                isPrimaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                primaryBtnBackSideIcon={modalActionButtonLoading === true ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                confirmationTopic="Are you sure?"
                confirmationMessage={`Do you want to skip this question? You cannot answer this question later.`}
                secondaryBtnName="backBtn"
                secondaryBtnType="button"
                secondaryBtnCssType="dangerBtn"
                secondaryBtnLabel="Cancel"
                primaryBtnName="submitBtn"
                primaryBtnType="button"
                primaryBtnCssType="successBtn"
                primaryBtnLabel="Yes, Skip!"
                confirmationType='success'
            />

            {isPageloading == true ?
                (<div className={styles.loadingDiv}>
                    <Col sm={{ span: 4, offset: 4 }}>
                        <CSSLoader />
                    </Col>
                </div>)
                :

                (< div >

                    <Col>
                        <Col>

                        </Col>
                        <Col>
                            <div className={styles.pageTopicCompanyName}>{quizDetails?.company_name}</div>
                            <div className={styles.pageTopic}>{quizDetails?.quiz_title}</div>
                        </Col>
                        <Col md={{ span: 8, offset: 2 }} xs={{ span: 12 }} className={styles.quizBox}>

                            <Col className="text-end mb-3">
                                <div > {quizDetails?.remaining_time ? <Countdown date={quizDetails?.expiration_time} renderer={setCountDownTime} ref={countDownRef} onComplete={onCountDownCompleted} zeroPadTime={2} /> : null}</div>
                            </Col>

                            {isQuestionloading == true ?
                                (<div className={styles.questioLoadingDiv}>
                                    <Col sm={{ span: 4, offset: 4 }} className={styles.loaderSecond}>
                                        <CSSLoader />
                                    </Col>
                                </div>)
                                :
                                (<div>
                                    <div className={styles.questionNameDiv}>
                                        <p className={styles.questionNameText}>{questionDetails?.question_name}</p>
                                    </div>

                                    <Formik
                                        enableReinitialize
                                        onSubmit={(values) => submitAnswers(values)}
                                        initialValues={{ answers: questionDetails.answers, answer_id: [] }}
                                    >
                                        {({
                                            errors,
                                            handleChange,
                                            handleSubmit,
                                            setFieldValue,
                                            submitCount,
                                            resetForm,
                                            values
                                        }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <Col className={styles.questionBox}>
                                                    <Col className={styles.questionTextMainDiv}>
                                                        <p className={styles.questionText}> <span className={styles.quesNo}></span> {questionDetails?.question}</p>
                                                    </Col>
                                                    <Col className='ms-3'>

                                                        <div>

                                                            <FieldArray name="answers">
                                                                {() => (values.answers.map((answer, i) => {

                                                                    return (
                                                                        <Row key={i}>

                                                                            <div className={styles.answerMainDiv}>
                                                                                {questionDetails.is_multiple_answer_question ?
                                                                                    <div className='mt-0 ms-4'>
                                                                                        <CustomCheckBox
                                                                                            id={i}
                                                                                            controlId={`answer.${i}`}
                                                                                            name={`answer.${i}.is_correct`}
                                                                                            handleOnChange={(e) => { setFieldValue(`answers.${i}.is_correct`, !answer.is_correct); resetNoAnswerError(); }}
                                                                                            type="defaultCheckBox" y
                                                                                            value={answer.is_correct}
                                                                                            ischecked={answer.is_correct}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <div className={styles.radioCheck}>
                                                                                        <Form.Check
                                                                                            inline
                                                                                            name="answers"
                                                                                            type="radio"
                                                                                            checked={answer.is_correct === true ? true : false}
                                                                                            className={styles.radioBtns}
                                                                                            onChange={() => { setFieldValue(`answers.${i}.is_correct`, !answer.is_correct); handleRadioButtonChange(values.answers, i); resetNoAnswerError(); }}
                                                                                            id={i}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {questionDetails.is_multiple_answer_question ?
                                                                                <Col className={styles.multipleAnswers}>
                                                                                    <p>{answer.answer}</p>
                                                                                </Col>
                                                                                :
                                                                                <Col className='ms-0 ps-0'>
                                                                                    <p>{answer.answer}</p>
                                                                                </Col>
                                                                            }

                                                                        </Row>
                                                                    );
                                                                }))}
                                                            </FieldArray>
                                                            {showEmptyAnswersError && <p className={styles.errorMessage}>Please select atleast one answer</p>}
                                                        </div>
                                                    </Col>

                                                </Col>
                                                <Col md={{ span: 5, offset: 7 }} xs={{ span: 12 }} className={styles.ButtonRow}>
                                                    <Row className={styles.ButtonSecondRow} >
                                                        <Col className='m-0 p-0'>
                                                            <CustomButton
                                                                buttonType="button"
                                                                label="Skip"
                                                                type="defaultBtn"
                                                                disabled={submitAnswerButtonLoading || skipQuestionButtonLoading || isTimeOver}
                                                                backicon={skipQuestionButtonLoading ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                                                                handleClick={() => { handleSkipQuestion(); }}
                                                            />

                                                        </Col>
                                                        <Col className='ms-3 mr-1 p-0'>{
                                                            (questionDetails.is_the_last_question) ?
                                                                <CustomButton
                                                                    label="Submit & Finish"
                                                                    type="primaryBtn"
                                                                    buttonType="submit"
                                                                    disabled={submitAnswerButtonLoading || skipQuestionButtonLoading || isTimeOver}
                                                                    backicon={submitAnswerButtonLoading ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                                                                />
                                                                :
                                                                <CustomButton
                                                                    label="Next"
                                                                    type="primaryBtn"
                                                                    buttonType="submit"
                                                                    disabled={submitAnswerButtonLoading || skipQuestionButtonLoading || isTimeOver}
                                                                    backicon={submitAnswerButtonLoading ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                                                                />
                                                        }

                                                        </Col>
                                                    </Row>

                                                </Col>

                                            </Form>
                                        )}

                                    </Formik>

                                    <div className='mt-3'>
                                        <div className={styles.numberRow}>
                                            <svg className={styles.numberRowSvg} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                <path d="M10.0467 4.07069L6.09432 8.11773L10.1414 12.0701" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            &nbsp;&nbsp;
                                            <p className={styles.numberRowText}>
                                                {questionDetails.question_number} / {quizDetails.total_question_count}
                                            </p>
                                            &nbsp;&nbsp;
                                            <svg className={styles.numberRowSvg} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M6 12L10 8L6 4" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>)
                            }


                        </Col>
                    </Col>
                </div >)
            }
        </div >
    )
}
export default QuizBody

