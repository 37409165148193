import React from 'react';
import styles from './response.module.scss'


export default function OfferExpired(props) {

    return (

        <div >
            <div className={styles.container}>
                <p className={styles.contentExpired}>
                    Sorry, this  job offer has been expired
                </p>

            </div>

        </div>


    )
}