import axios from 'axios';
import { axiosInstance } from '../../api';
import { errorHandler } from '../../interceptors';
const BASE_PATH = '/external_assessment';



export let getAssessmentDetails = async (session_id) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/get_external_assessment_details', {
            external_assessment_session_id: session_id
        });
        return value;
    } catch (error) {
        return error
    }
}

export let submitAssessment = async (sessionID, AssessmentFileType) => {
    try {
        let formData = new FormData();
        formData.append('file', AssessmentFileType);
        let value = await axiosInstance.post(BASE_PATH + '/submit_external_assessment_uploads', {
            external_assessment_session_id: sessionID,
            file_extention: AssessmentFileType
        })
        return value;
    } catch (error) {
        return error
    }
}

export let getUploadedAssessmentURL = async (session_id, assessmentfiletype) => {
    try {
        let values = await axiosInstance.post(BASE_PATH + '/get_presigned_urls_for_external_assessement_uploads', {
            external_assessment_session_id: session_id,
            file_extention: assessmentfiletype
        })
        return values;
    } catch (err) {
        return err
    } 
}

export let uploadExternalAssessmentFile = async (UploadedAssessmentURL, AssessmentFile) => {
    try {

        let value = await axios.put(UploadedAssessmentURL, AssessmentFile, {
            headers: {
                'Content-Type': AssessmentFile.type
            }
        })
        return value;
    } catch (err) {
        return err;

    }
}