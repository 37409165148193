import React from 'react'
import { Card } from 'react-bootstrap';
import styles from './chatBox.module.scss';

function ChatBox(props) {
    return (
        <Card className={props.variant === 'incoming' ? styles.incommingMessage : props.variant === 'outgoing' && styles.outgoingMessage} key={props.keyIndex}>
            <Card.Body className={styles.messageBox}>
                <p className={styles.senderName}>{props.senderName}</p>
                <p className={styles.bodyMessage}>{props.content}</p>
                <span className={styles.sentTime}>{props.timestamp}</span>
            </Card.Body>
        </Card>
    )
}

export default ChatBox;
