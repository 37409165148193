import { Modal} from 'react-bootstrap';
import React from 'react'
import styles from './CustomCandidateWaitingToJoinInterviewPopUpBox.module.scss';


export const CustomCandidateWaitingToJoinInterviewPopUpBox = (props) => {
    return (
        <div>
            <Modal
                show={props.show}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className={styles.modalBodyWrapper}>
                    <div>
                        
                        <div className={styles.waitingLoaderWrapper}><div className={styles.loader}></div><p className={styles.modalHeaderTopic}>{props.topic}</p></div>
                        <p className={styles.modalHeaderSubTopic}>{props.message}&nbsp;</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
