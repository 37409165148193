import React, { useState, useEffect } from 'react';
import { Row, Col, ProgressBar, Container, Image, Badge } from 'react-bootstrap';
import styles from './quizDetails.module.scss';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { CustomCheckBox } from '../../shared/customCheckBox';
import { CustomButton } from '../../shared/customButton'
import { Redirect, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
//import { getAssessmentDetails, getUploadedAssessmentURL, submitAssessment, uploadExternalAssessmentFile } from '../../services/util/externalAssessment';
import { CustomPopUpBox } from '../../shared/customPopUpBox';
import { CustomConfirmationModal } from '../../shared/customConfirmationModal';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';
import { getQuizDetails } from '../../../services/util/quiz';
import { Helmet } from "react-helmet";
import * as Scroll from 'react-scroll';

export default function QuizDetails(props) {
    const history = useNavigate();

    let [isAcknowledgeConfirmed, setAcknowledgeConfirmed] = useState(false);
    let [isLoading, setLoading] = useState(props.status);
    let [quizDetails, setQuizDetails] = useState('');
    let [showSubmitPopup, setShowSubmitPopup] = useState(false);
    let [modalActionButtonLoading, setModalActionButtonLoading] = useState(false);
    let [SubmitData, setSubmitData] = useState();
    let [primaryColor, setPrimaryColor] = useState('');

    const changeAcknowledgeState = () => {
        setAcknowledgeConfirmed(!isAcknowledgeConfirmed);
    }

    let { id } = useParams();

    const submitData = () => {
        if (isAcknowledgeConfirmed) {
            // setLoading(true);
            setShowSubmitPopup(true);
            // setSubmitData(values);
        }
    }

    const submitConfirmed = () => {
        props.nextPage(2);
    }

    const handleClose = () => {
        setShowSubmitPopup(false);
    }


    const setCountDownTime = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            //loadQuizDetails();
            props.refreshData();
        } else {
            // Render a countdown
            if (days) {
                return (
                    <span>
                        {days} days  {hours} hrs
                    </span>
                );
            } else if (hours) {
                return (
                    <span>
                        {hours} hs {minutes} min
                    </span>
                );
            }
            else if (minutes) {
                return (
                    <span>
                        {minutes} min {seconds} sec
                    </span>
                );
            } else {
                return (
                    <span>
                        {seconds} sec
                    </span>
                );
            }

        }
    };

    const Element = Scroll.Element;
    const scroller = Scroll.scroller;
    function moveToJobDetails() {
        scroller.scrollTo('scrollToJobDetailsElement')
    }

    useEffect(() => {

        setQuizDetails(props.data)
        setPrimaryColor(props.primaryColor)
    }, [props]);

    //set company selected color as the primary color
    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty(
            "--selected-primary-color", primaryColor
        );
    }, [primaryColor]);


    return (
        <div>
            {!quizDetails ?
                <div>
                    <div className={styles.overlay}></div>
                    <div className={`${styles.spanner} ${styles.show}`}>
                        <div className={styles.loader}></div>
                        <p className={styles.loaderText}>Please Wait...</p>
                    </div>
                </div>
                :
                <div>
                    <Helmet>
                        <title>Quiz Details | HireTrace</title>
                    </Helmet>
                    <CustomConfirmationModal
                        show={showSubmitPopup}
                        cancelIconclick={handleClose}
                        onSecondaryBtnClick={handleClose}
                        onPrimaryBtnClick={submitConfirmed}
                        isSecondaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                        isPrimaryBtnDisabled={modalActionButtonLoading === true ? true : false}
                        primaryBtnBackSideIcon={modalActionButtonLoading === true ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                        confirmationTopic="Are you sure?"
                        confirmationMessage={`Once you click the "Yes, Start!". The quiz will start. Please note that you have to answer all questions within the first attempt.`}
                        secondaryBtnName="backBtn"
                        secondaryBtnType="button"
                        secondaryBtnCssType="defaultBtn"
                        secondaryBtnLabel="Cancel"
                        primaryBtnName="submitBtn"
                        primaryBtnType="button"
                        primaryBtnCssType="successBtn"
                        primaryBtnLabel="Yes, Start!"
                        confirmationType='success'
                    />
                    <div>
                        <Row className={styles.bodyRow}>
                            <Col xs={12} md={6} className={styles.submissionFormView}>
                                <Container className={styles.mainContainer}>
                                    <Col lg={{ span: 10, offset: 1 }}>
                                        <Col className={styles.companySecondaryHeaderBox}>

                                        </Col>
                                        <Col className={styles.pageTopicDiv}>
                                            <p className={styles.pageTopic}>Quiz Details</p>
                                            <p className={styles.pageSecondTopic} onClick={moveToJobDetails}>View Quiz Details</p>
                                            <p className={styles.pageIntro}>You have been invited to participate in a Quiz . Please read the guidelines and do it and submit your answers.</p>
                                        </Col>
                                        <Col className="mt-5 ms-3">
                                            <Row className={styles.Guidelines}>
                                                <Col xs={6} lg={3} className={styles.guidelineFourthcol}>
                                                    <p className={styles.nameTag}>Quiz Type</p>
                                                    <p className={styles.nameTagValue}>: </p>
                                                </Col>
                                                <Col className={styles.guidelineFirstcol}>
                                                    <p className={styles.nameTagSecondValue}> MCQ Questions </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="mt-1 ms-3">
                                            <Row className={styles.Guidelines}>
                                                <Col xs={6} lg={3} className={styles.guidelineFourthcol}>
                                                    <p className={styles.nameTag}>Specialization</p>
                                                    <p className={styles.nameTagValue}>: </p>
                                                </Col>
                                                <Col  className={styles.guidelineFirstcol}>
                                                    <p className={styles.nameTagSecondValue}> {quizDetails?.quiz_specialization} </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="mt-1 ms-3">
                                            <Row className={styles.Guidelines}>
                                                <Col xs={6} lg={3} className={styles.guidelineFourthcol}>
                                                    <p className={styles.nameTag}>Quiz Time Duration</p>
                                                    <p className={styles.nameTagValue}>: </p>
                                                </Col>
                                                <Col  className={styles.guidelineFirstcol}>
                                                    <p className={styles.nameTagSecondValue}> {quizDetails?.duration} minutes</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="mt-2 ms-3">
                                            <Row className={styles.Guidelines}>
                                                <Col xs={6} lg={3} className={styles.guidelineFourthcol}>
                                                    <p className={styles.nameTag}>Question Count</p>
                                                    <p className={styles.nameTagValue}>: </p>
                                                </Col>
                                                <Col  className={styles.guidelineFirstcol}>
                                                    <p className={styles.nameTagSecondValue}> {quizDetails?.question_count} </p>
                                                </Col>
                                            </Row>
                                        </Col>

                                        {quizDetails?.description && (
                                            <Col className="mt-2 ms-3">
                                                <Row className={styles.Guidelines}>
                                                    <Col xs={6} lg={3} className={styles.guidelineFourthcol}>
                                                        <p className={styles.nameTag}>Descriptions</p>
                                                        <p className={styles.nameTagValue}>: </p>
                                                    </Col>
                                                    <Col className={styles.guidelineFirstcol}>
                                                        <p className={styles.nameTagSecondValue}> {quizDetails?.description} </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                        <hr className={styles.dividerLine} />
                                        <div className="mt-4">

                                            <div className="mt-3">
                                                <CustomCheckBox
                                                    id="acceptConditions"
                                                    controlId="acceptConditions"
                                                    name="condition"
                                                    label="I hereby confirm that I have read and understand all the quiz instructions and I'm ready to answer."
                                                    handleOnChange={() => changeAcknowledgeState()}
                                                    type="defaultCheckBox"
                                                    ischecked={isAcknowledgeConfirmed}
                                                />
                                            </div>
                                            <div className={styles.submitButton}>

                                                <CustomButton
                                                    buttonType="submit"
                                                    label="Start Quiz"
                                                    type="primaryBtn"
                                                    disabled={
                                                        (isLoading === true || isAcknowledgeConfirmed === false) ? true : false
                                                    }
                                                    backicon={isLoading === true && <i className="fas fa-circle-notch fa-spin"></i>}
                                                    handleClick={() => submitData()}
                                                />

                                            </div>

                                        </div>


                                        <div className={styles.submitTextCol}>
                                            <p className={styles.signupBottomMessage}>By clicking the &quot;Start Quiz&quot; button, you&lsquo;re agreeing to accept</p>
                                            <p className={styles.termsPara}><a href="https://hiretrace.io/privacy-policy" className={styles.termsLink} target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://hiretrace.io/terms-and-conditions" className={styles.termsLink} target="_blank" rel="noreferrer">Terms of Use.</a></p>
                                        </div>


                                    </Col>
                                </Container>
                            </Col>


                            <Col xs={12} md={6} className={styles.descriptionBox}>
                                <Col xs={12} className={styles.descriptionInnerBox}>
                                    <Col className={styles.companyPrimaryHeaderBox}>
                                        <Col className="text-end mb-3">
                                            <div className={styles.timeleft}> <Countdown date={Date.now() + quizDetails?.remaining_days_before_expiration} renderer={setCountDownTime} /> left</div>
                                        </Col>
                                        <Col className="text-center mt-5">
                                            {quizDetails?.company_logo_url ?
                                                <Col xs={{ span: 10, offset: 1 }}>
                                                    <Image src={quizDetails?.company_logo_url} className={styles.companyLogo} alt={quizDetails.company_name + " logo"} />
                                                </Col>
                                                :
                                                <p className={styles.companyName}>{quizDetails?.company_name}</p>
                                            }

                                        </Col>
                                        <Col className="mt-3">
                                            <Row className={styles.GuidelinesRow}>
                                                <Col lg={2} xs={5}className={styles.guidelineFirstcol}>
                                                    <p className={styles.nameTag}> Candidate Name</p>
                                                </Col>
                                                <Col className={styles.guidelineFirstcol}>
                                                    <p className={styles.nameTagValue}>: {quizDetails?.candidate_name} </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row className={styles.GuidelinesRow}>
                                            <Col lg={2}  xs={5} className={styles.guidelineFirstcol}>
                                                    <p className={styles.nameTag}>Position</p>
                                                </Col>
                                                <Col className={styles.guidelineFirstcol}>
                                                    <p className={styles.nameTagValue}>: {quizDetails?.hiring_position} </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row className={styles.GuidelinesRow}>
                                                <p className={styles.nameTagValue}>You are required to answer this quiz as a requirement of the evaluation process of the above vacancy. </p>

                                            </Row>
                                        </Col>
                                      
                                    </Col>

                                    <Col className='mt-1'>
                                        {/* scroll to element in mobile view */}
                                        <Element name="scrollToJobDetailsElement"></Element>
                                        
                                        <Container className={styles.subTopicMainBody}>
                                            <Col className={styles.pageTopicSecondDiv}>
                                                <Row>
                                                    <div  className={styles.pageGuidlineTopic}><p>Quiz Instructions</p></div>
                                                </Row>
                                            </Col>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>1.&nbsp;&nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>Complete the quiz before the deadline.</div>
                                            </div>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>2.&nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>Use a laptop or a desktop computer to take the quiz and make sure to have a stable internet connection.</div>
                                            </div>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>3.&nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>You only get 1 attempt to answer.</div>
                                            </div>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>4.&nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>After you answer or skip a question, you cannot change it again.</div>
                                            </div>
                                            <div className={styles.guidlinecontentdiv}>
                                                <div className={styles.guidelinecontentCol}>5.&nbsp;</div>
                                                <div className={styles.guidelinecontentCol}>After starting the quiz, do not go outside the brower tab. If you do so, you will get disqualify.</div>
                                            </div>

                                            <div className={styles.guidlineTopicDiv}>

                                                <div className={styles.pageGuidlineTopic}>
                                                    <p>Important</p>
                                                </div>

                                            </div>
                                            <Row className='mt-4 ml-1'>
                                                <div className={styles.guidlinecontentdiv}>
                                                    <div className={styles.guidelinecontentCol}>If you need more information about this quiz, please reach to {quizDetails.company_name}'s  HR team via <a href={`mailto:${quizDetails?.contact_email}`} className={styles.guidelineLinks}>{quizDetails.contact_email}</a>.</div>
                                                </div>
                                                <div className={styles.guidlinecontentdiv}>
                                                    <div className={styles.guidelinecontentCol}>If you face any issues when taking the quiz, please contact <a href={`mailto:support@hiretrace.io`} className={styles.guidelineLinks}>support@hiretrace.io</a>.</div>
                                                </div>
                                            </Row>
                                        </Container>
                                    </Col>

                                    <Row className='text-center mt-5'>
                                        <Col className={styles.footerCreditBox}>
                                            <p>Powered By <span> <a className={styles.footerCreditLink} href="https://hiretrace.io" rel="noreferrer" target="_blank"> HireTrace</a></span></p>
                                        </Col>
                                    </Row>

                                </Col>


                            </Col>
                        </Row>

                    </div>
                </div>
            }
        </div>
    )
}
